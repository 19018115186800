import AppBar from '@mui/material/AppBar';
import { errorBoundary } from 'elements/ErrorBoundary';
import { HideOnScroll } from 'elements/HideOnScroll';
import { useModalsJoinNowSet } from 'hooks/useModalsJoinNowSet';
import { useResizeObserver } from 'hooks/useResizeObserver';
import { useUnion } from 'hooks/useUnion';
import { useUser } from 'hooks/useUser';
import { Alert } from 'modules/Alert';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { routes } from 'routes';
import {
  HeaderContainer,
  HeaderPlaceholder,
  Logo,
  Toolbar,
  ToolbarHead,
  ToolbarHeadButtonGroup,
} from './HeaderElements';
import { SearchButton } from './HeaderSearchButton';
import { NavigationMenu } from './Menus';
import { AccountMenu } from './Menus/AccountMenu';
import { Participate } from './Menus/Participate';
import { ToolbarNavigation } from './Menus/ToolbarNavigation';

export const Header = () => {
  const setModalsRegister = useModalsJoinNowSet();
  const [user] = useUser();
  const isAuth = !!user;
  const union = useUnion();
  const navigate = useNavigate();
  const [height, setHeight] = useState(64);
  const ref = useResizeObserver<HTMLDivElement>((entry) =>
    setHeight(entry[0].borderBoxSize[0].blockSize || 64)
  );

  const navTo = (path?: string) => () =>
    !isAuth ? setModalsRegister(true) : path && navigate(path);

  return (
    <>
      <HeaderPlaceholder style={{ height }} />
      <HideOnScroll>
        <HeaderContainer ref={ref}>
          {errorBoundary(<Alert />)}
          <AppBar>
            <Toolbar>
              <ToolbarHead>
                <Logo
                  src={union.logo}
                  alt={union.nameFull}
                  onClick={() => navigate(routes.HOME)}
                />
                <ToolbarHeadButtonGroup>
                  <SearchButton />
                  {!isAuth && <Participate />}
                  <AccountMenu {...{ navTo, isAuth }} />
                  <NavigationMenu {...{ navTo, isAuth }} />
                </ToolbarHeadButtonGroup>
              </ToolbarHead>
              <ToolbarNavigation {...{ navTo }} />
            </Toolbar>
          </AppBar>
        </HeaderContainer>
      </HideOnScroll>
    </>
  );
};
