import Link from '@mui/material/Link';
import styled from '@mui/material/styles/styled';
import { useTranslation } from 'react-i18next';
import { navItems } from '.';

const ToolbarNavigationShell = styled('div')(({ theme }) => ({
  width: '100%',
  justifyContent: 'space-between',
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(1),
  display: 'none',
  [theme.breakpoints.up('md')]: {
    display: 'flex',
    justifyContent: 'space-around',
  },
}));

const NavigationItem = styled(Link)<{ disabled?: boolean }>(
  ({ theme, disabled }) => ({
    color: theme.palette.text.primary,
    fontWeight: 'bold',
    cursor: 'pointer',
    whiteSpace: 'nowrap',
    ...(disabled
      ? { color: theme.palette.action.disabled, pointerEvents: 'none' }
      : {}),
  })
);
NavigationItem.defaultProps = {
  underline: 'hover',
};

interface ToolbarNavigationProps {
  navTo: (path?: string) => () => void;
}

export function ToolbarNavigation({ navTo }: ToolbarNavigationProps) {
  const { t } = useTranslation();
  return (
    <ToolbarNavigationShell>
      {navItems.map(({ key, path }) => (
        <NavigationItem key={key} onClick={navTo(path)} disabled={!path}>
          {t(key)}
        </NavigationItem>
      ))}
    </ToolbarNavigationShell>
  );
}
