import { PasswordFormChangeOrReset } from './PasswordFormChangeOrReset';
import { PasswordFormForgotten } from './PasswordFormForgotten';

export function PasswordForm({
  phase,
  isLoading,
}: {
  phase?: number;
  isLoading?: boolean;
}) {
  if (phase === 1) return <PasswordFormForgotten isLoading={isLoading} />;
  return <PasswordFormChangeOrReset isLoading={isLoading} />;
}
