import Box from '@mui/material/Box';
import type { LinkProps } from '@mui/material/Link';
import Link from '@mui/material/Link';
import styled from '@mui/material/styles/styled';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import Typography from '@mui/material/Typography';
import { ReactComponent as Avatar } from './avatar.svg';
import { ReactComponent as Email } from './email.svg';
import { ReactComponent as Fax } from './fax.svg';
import { ReactComponent as Phone } from './phone.svg';

const icons = { phone: Phone, fax: Fax, email: Email };
const prefix = { phone: 'tel', fax: 'fax', email: 'mailto' };

const getAvatarStyles = (width: number | string) => ({
  borderRadius: '50%',
  width: width,
  height: width,
  minWidth: width,
  minHeight: width,
});

export const ContactShell = styled(Box)<{ fullWidth?: boolean }>(
  ({ theme, fullWidth }) => ({
    display: 'flex',
    gap: theme.spacing(4),
    width: fullWidth ? '100%' : undefined,
    [theme.breakpoints.down('sm')]: {
      wordWrap: 'break-word',
      hyphens: 'auto',
    },
  })
);

export const ContactPortraitImg = styled('img')(({ theme, width = 108 }) => ({
  objectFit: 'cover',
  position: 'absolute',
  top: 0,
  left: 0,
  ...getAvatarStyles(width),
}));

export const ContactPortraitPlaceholder = styled(Avatar)(
  ({ theme, width = 108 }) => ({
    fill: theme.palette.secondary.main,
    ...getAvatarStyles(width),
  })
);

export const ContactPortrait = ({ photo }: { photo?: string }) => (
  <div style={{ position: 'relative' }}>
    {photo && <ContactPortraitImg alt="portrait" src={photo} />}
    <ContactPortraitPlaceholder aria-label="portrait" />
  </div>
);

export const ContactDetails = styled('div')(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(0.5),
}));

export const ContactHeader = styled(Typography)(({ theme }) => ({
  fontFamily: 'Poppins',
  fontWeight: 'bold',
}));

const StyledIcon = styled(
  ({ type, ...rest }: { type: 'phone' | 'fax' | 'email' } & SvgIconProps) => (
    <SvgIcon component={icons[type]} {...rest} />
  )
)(({ theme }) => ({
  color: theme.palette.primary.main,
}));

interface ContactElementProps extends LinkProps {
  type: 'phone' | 'fax' | 'email';
  value?: string;
}

export const ContactElement = styled(
  ({ type, value, ...rest }: ContactElementProps) => {
    if (!value) return null;
    return (
      <Link
        {...rest}
        underline="hover"
        href={`${prefix[type]}:${value}`}
        target={type === 'email' ? '_blank' : undefined}
        rel="noreferrer noopener"
      >
        <StyledIcon type={type} />
        <span>{value}</span>
      </Link>
    );
  }
)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1),
  cursor: 'pointer',
  color: theme.palette.secondary.main,
  overflowWrap: 'anywhere',
}));
