import { useModalsJoinNowSet } from 'hooks/useModalsJoinNowSet';
import { useUnion } from 'hooks/useUnion';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router-dom';
import { routes } from 'routes';
import { useGetApiV1SliderForVisitorQuery } from 'store/api';
import { HeroElement } from './HeroElement';
import BgFirst from './slide-first.jpg';
import BgLast from './slide-last.jpg';

export const HeroVisitor: FC = () => {
  const { t } = useTranslation(undefined, { keyPrefix: 'hero' });

  const navigate = useNavigate();
  const { data, isLoading } = useGetApiV1SliderForVisitorQuery(undefined);
  const setModalsRegister = useModalsJoinNowSet();
  const union = useUnion();
  const unionName = union?.nameShort || '';

  const images = [
    {
      src: BgFirst,
      title: `${t('hello')}, ${t('welcome.formal')} ${unionName} ${t(
        'network'
      )}!`,
      text: `${t('sliderDescription')} ${unionName}.`,
      button: t('viewAllNews'),
      onClick: () => setModalsRegister(true),
    },
    {
      src: data?.snippet3?.previewImageUrl,
      title: data?.snippet3?.title,
      text: data?.snippet3?.teaser,
      button: t('readMore'),
      onClick: () =>
        navigate(
          generatePath(routes.NEWS_ITEM, {
            idOrUri: data?.snippet3?.id,
          })
        ),
    },
    {
      src: BgLast,
      title: t('fifthSlideTitle'),
      text: t('fifthSlideText'),
      button: t('fifthSlideTitle'),
      onClick: () =>
        window.open('https://www.dbb-vorteilswelt.de/aktuelles/', '_blank'),
    },
  ];

  return isLoading ? null : <HeroElement images={images} />;
};
