import styled from '@mui/material/styles/styled';
import { useTranslation } from 'react-i18next';

export const JoinNowTitle = styled((props) => {
  const { t } = useTranslation();
  return (
    <span {...props}>
      <span>{t('joinNow.title1')}</span>
      {t('joinNow.title2')}
    </span>
  );
})(({ theme }) => ({
  marginBlock: 0,
  fontSize: 30,
  '& span': {
    color: theme.palette.primary.main,
  },
}));
