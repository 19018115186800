import type { StateData } from 'modules/Register/geoData';
import { useGetApiV1AccountGetCurrentUserDataQueryState } from 'store/api';

export type User = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  userName: string;
  genderId: number;
  formalApproach: boolean;
  union: string;
  unionId: number;
  federalStateId: number;
  federalState: keyof StateData;
  userTypeId: number;
  isApproved: string;
  isActive: boolean;
  dateOfBirth: string;
};

export function useUser(): [
  null | User,
  ReturnType<typeof useGetApiV1AccountGetCurrentUserDataQueryState>
] {
  const result = useGetApiV1AccountGetCurrentUserDataQueryState(undefined);
  if (result.isSuccess) return [result.data, result];
  return [null, result];
}
