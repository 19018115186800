import { CSSProperties } from 'react';
import { TemplateImageOnly } from './Templates/TemplateImageOnly';
import { TemplateInitial } from './Templates/TemplateInitial';

interface BennerTemplateProps {
  templateId: number;
  img?: string | null;
  title?: string | null;
  text?: string | null;
  linkText?: string | null;
  link?: string | null;
  animation?: string | null;
  style?: CSSProperties;
}

export function BannerTemplate({
  templateId,
  img,
  title,
  text,
  linkText,
  link,
  animation,
  style,
}: BennerTemplateProps) {
  if (templateId === 1)
    return (
      <TemplateInitial
        {...{
          img,
          linkText,
          text,
          title,
          link,
          animation,
          style,
        }}
      />
    );
  if (templateId === 2)
    return (
      <TemplateImageOnly
        {...{
          img,
          link,
          style,
        }}
      />
    );
  return null;
}
