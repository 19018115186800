import FormControl from '@mui/material/FormControl';
import useDebounce from 'hooks/useDebounce';
import { ChangeEventHandler, KeyboardEvent, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { routes } from 'routes';
import {
  ButtonClose,
  ButtonSearch,
  SearchActions,
  SearchInput,
} from './SearchElements';

interface SearchBarProps {
  handleFetchingSearchResults: (searchTerm: string) => void;
  handleSearchTermChange: (searchTerm: string) => void;
  handleShowLiveSearchResults: (showSearchResults: boolean) => void;
  handleInitDataForSearchPage: () => void;
  searchTerm: string;
}

const SearchBar = ({
  handleFetchingSearchResults,
  handleSearchTermChange,
  handleShowLiveSearchResults,
  handleInitDataForSearchPage,
  searchTerm,
}: SearchBarProps) => {
  const navigate = useNavigate();

  const handleSearchChange: ChangeEventHandler<HTMLInputElement> = (e) =>
    handleSearchTermChange(e.target.value);

  const handleSearchReset = () => {
    handleSearchTermChange('');
    handleShowLiveSearchResults(false);
  };

  const handleSearchLinkClick = () => {
    if (!searchTerm || searchTerm.length <= 2) {
      return;
    }
    handleInitDataForSearchPage();
    navigate(routes.SEARCH);
    handleShowLiveSearchResults(false);
  };

  const handleEnterKeyPress = (e: KeyboardEvent) => {
    if (e.key === 'Enter') handleSearchLinkClick();
  };

  const debouncedSearchTerm = useDebounce(searchTerm, 1000);

  useEffect(
    () => {
      if (!searchTerm || searchTerm.length <= 2)
        return void handleShowLiveSearchResults(false);
      handleFetchingSearchResults(searchTerm);
      handleShowLiveSearchResults(true);
    },
    // eslint-disable-next-line
    [debouncedSearchTerm]
  );

  return (
    <FormControl style={{ width: '100%' }}>
      <SearchInput
        type="text"
        id="search"
        onChange={handleSearchChange}
        onKeyDown={handleEnterKeyPress}
        autoComplete="off"
        value={searchTerm}
      />
      <SearchActions>
        <ButtonClose onClick={handleSearchReset} />
        <span />
        <ButtonSearch onClick={handleSearchLinkClick} />
      </SearchActions>
    </FormControl>
  );
};
export default SearchBar;
