import Typography from '@mui/material/Typography';
import { useApproach } from 'hooks/useApproach';
import { useTranslation } from 'react-i18next';
import { Accent } from './ContactsMessageElements';

export function ContactsMessageTitle() {
  const { t } = useTranslation(undefined, { keyPrefix: 'contacts.message' });
  const approach = useApproach();

  return (
    <Typography variant="h5">
      <div>
        {t('title1')}
        <Accent>{approach}</Accent>?
      </div>
      <div>{t('title2')}</div>
    </Typography>
  );
}
