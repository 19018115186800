import Button from '@mui/material/Button';
import { AppDialog } from 'elements/AppDialog';
import { getApproachT } from 'helpers/getApproachT';
import { useModals } from 'hooks/useModals';
import { useModalsErrorSet } from 'hooks/useModalsErrorSet';
import { useUser } from 'hooks/useUser';
import { useTranslation } from 'react-i18next';
import { selectUserInformal } from 'selectors';

export default function ErrorDialog() {
  const { t } = useTranslation(undefined, { keyPrefix: 'error' });
  const modals = useModals();
  const [user] = useUser();
  const informal = selectUserInformal(user);
  const setModalsError = useModalsErrorSet();
  const handleClose = () => setModalsError(false);

  const at = getApproachT(t)(informal);

  return (
    <AppDialog
      open={modals.error}
      onClose={handleClose}
      heading={t('heading')}
      content={at('content')}
      actions={
        <Button variant="contained" fullWidth onClick={handleClose}>
          {t('ok')}
        </Button>
      }
    />
  );
}
