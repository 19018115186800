import { isAuth } from 'helpers/isAuth';
import debounce from 'lodash/debounce';
import { useCallback, useRef } from 'react';
import {
  apiPreferences,
  Bands,
  useGetApiV2AccountPreferencesQueryState,
  usePatchApiV2AccountPreferencesMutation,
} from 'store/api/apiPreferences';
import { useAppDispatch } from './useRedux';

const initBands: NonNullable<Bands[keyof Bands]> = [];

export function useBands(
  band: keyof Bands
): [number[], (ids: number[]) => void] {
  const { data } = useGetApiV2AccountPreferencesQueryState(undefined);

  const [patchBandsMutation] = usePatchApiV2AccountPreferencesMutation();
  const patchApi = useRef(
    debounce(
      (bands: Partial<Bands>) => patchBandsMutation({ body: { bands } }),
      2048
    )
  ).current;

  const dispatch = useAppDispatch();
  const patchBands = useCallback(
    function (ids: number[]) {
      dispatch(
        apiPreferences.util.updateQueryData(
          'getApiV2AccountPreferences',
          undefined,
          (draft) => void (draft.bands[band] = ids)
        )
      );
      if (isAuth()) patchApi({ [band]: ids });
    },
    [band, dispatch, patchApi]
  );

  return [data?.bands[band] || initBands, patchBands];
}
