import { routes } from 'routes';
import { ReactComponent as Committees } from './icons/committees.svg';
import { ReactComponent as Contacts } from './icons/contacts.svg';
import { ReactComponent as News } from './icons/news.svg';
import { ReactComponent as Topics } from './icons/topics.svg';

export const navItems = [
  { key: 'menu.newsAndCurrent', path: routes.NEWS, icon: News },
  { key: 'menu.topicsAndPositions', path: routes.TOPICS, icon: Topics },
  {
    key: 'menu.servicesAndDownload',
    path: routes.SERVICE_AND_DOWNLOAD,
    icon: Committees,
  },
  { key: 'menu.committees', path: routes.COMMITTEES, icon: Committees },
  { key: 'menu.contact', path: routes.CONTACTS, icon: Contacts },
];
