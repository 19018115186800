import DisabledByDefaultRoundedIcon from '@mui/icons-material/DisabledByDefaultRounded';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import Box from '@mui/material/Box/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import InputBase from '@mui/material/InputBase';
import { styled } from '@mui/material/styles';

export const SearchWrapper = styled(Box)(({ theme }) => ({
  width: '100%',
  maxWidth: `min(calc(100vw - ${theme.spacing(6)}), ${
    theme.breakpoints.values.lg
  }px)`,
  padding: `${theme.spacing(6)} 0`,
  margin: '0px auto',
  position: 'relative',
  zIndex: '2',
  [theme.breakpoints.down('sm')]: {
    padding: `${theme.spacing(2)} 0`,
  },
}));

export const SearchActions = styled('div')(({ theme }) => ({
  position: 'absolute',
  right: '0px',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  paddingRight: theme.spacing(4),
  '& > span': {
    display: 'inline-block',
    width: '1px',
    height: '1.5rem',
    margin: '0 0.5rem',
    backgroundColor: theme.palette.secondary.main,
  },
  [theme.breakpoints.down('sm')]: {
    paddingRight: theme.spacing(2),
  },
}));

export const ResultsShell = styled(Box)(({ theme }) => ({
  position: 'absolute',
  left: '0px',
  top: `calc(100% - ${theme.spacing(5.5)})`,
  width: '100%',
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.spacing(1),
  padding: theme.spacing(0.5),
  border: `1px solid ${theme.palette.grey[700]}`,
  [theme.breakpoints.down('sm')]: {
    top: `calc(100% - ${theme.spacing(1.5)})`,
  },
}));

export const ResultsList = styled('ul')(({ theme }) => ({
  margin: '0',
  listStyle: 'none',
  padding: '0px',
}));

export const ResultsListItem = styled('li')(({ theme }) => ({
  borderRadius: theme.spacing(0.5),
  '&:hover': {
    backgroundColor: theme.palette.grey[500],
    color: theme.palette.text,
  },
  '& a': {
    display: 'block',
    padding: `${theme.spacing(1)} ${theme.spacing(3)}`,
    color: theme.palette.text.primary,
    textDecoration: 'none',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    '&:hover, &:focus:hover': {
      color: theme.palette.common.white,
    },
  },
  [theme.breakpoints.down('sm')]: {
    '& a': {
      padding: `${theme.spacing(1)} ${theme.spacing(1.5)}`,
    },
  },
}));

export const AllResultsLink = styled('div')(({ theme }) => ({
  borderTop: `1px solid ${theme.palette.grey[400]}`,
  marginTop: theme.spacing(0.5),
  paddingTop: theme.spacing(0.5),
  '& a': {
    display: 'block',
    padding: `${theme.spacing(1)} ${theme.spacing(3)}`,
    color: theme.palette.primary.main,
    textDecoration: 'none',
    '&:hover, &:focus': {
      color: theme.palette.primary.main,
    },
  },
  [theme.breakpoints.down('sm')]: {
    '& a': {
      padding: `${theme.spacing(1)} ${theme.spacing(1.5)}`,
    },
  },
}));

export const NoResults = styled('div')(({ theme }) => ({
  padding: `${theme.spacing(0.5)} ${theme.spacing(2)}`,
  color: theme.palette.text.primary,
}));

export const ButtonSearch = styled(IconButton)(({ theme }) => ({
  lineHeight: 0,
  padding: 0,
}));
ButtonSearch.defaultProps = {
  children: <SearchOutlinedIcon />,
};

export const ButtonClose = styled(IconButton)(({ theme }) => ({
  lineHeight: 0,
  padding: 0,
}));
ButtonClose.defaultProps = {
  children: <DisabledByDefaultRoundedIcon />,
};

export const SearchInput = styled(InputBase)(({ theme }) => ({
  width: '100%',
  backgroundColor: theme.palette.grey[200],
  padding: `${theme.spacing(2)} ${theme.spacing(14)} ${theme.spacing(
    2
  )}  ${theme.spacing(4)}`,
  borderRadius: `${theme.spacing(2)}`,
  '& input': {
    borderRadius: 0,
  },
  [theme.breakpoints.down('sm')]: {
    borderRadius: `${theme.spacing(1)}`,
    padding: `${theme.spacing(1)} ${theme.spacing(12)} ${theme.spacing(
      1
    )}  ${theme.spacing(2)}`,
  },
}));

export const SearchShell = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(4),
  width: '100%',
}));

export const SearchResultsTotal = styled('h2')(({ theme }) => ({
  fontFamily: 'Poppins',
  fontWeight: 'bold',
  fontSize: 24,
  marginBottom: theme.spacing(4),
  '& strong': {
    color: theme.palette.accent,
  },
  [theme.breakpoints.down('sm')]: {
    marginBottom: theme.spacing(0),
  },
}));

export const SearchItem = styled('div')(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  [theme.breakpoints.down('sm')]: {
    wordWrap: 'break-word',
    hyphens: 'auto',
  },
}));

export const SearchItemTitle = styled('h2')(({ theme }) => ({
  fontFamily: 'Poppins',
  fontWeight: 'bold',
  fontSize: 24,
  marginTop: theme.spacing(2),
  [theme.breakpoints.down('sm')]: {
    marginTop: 0,
  },
}));

export const ResultsReadMore = styled(Button)(({ theme, hidden }) => ({
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(6),
  visibility: !hidden ? 'visible' : 'hidden',
  [theme.breakpoints.down('sm')]: {
    marginBottom: theme.spacing(4),
  },
}));
ResultsReadMore.defaultProps = { variant: 'contained' };

export const LoadMoreHolder = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
}));

export const LoadMore = styled(Button)(({ theme, hidden }) => ({
  marginTop: theme.spacing(2),
  visibility: !hidden ? 'visible' : 'hidden',
  marginLeft: 'auto',
  marginRight: 'auto',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    marginTop: theme.spacing(0),
  },
}));
LoadMore.defaultProps = { variant: 'contained' };
