import type { Theme } from '@mui/material';
import Drawer from '@mui/material/Drawer';
import ListItemIcon from '@mui/material/ListItemIcon';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import SvgIcon from '@mui/material/SvgIcon';
import Tooltip from '@mui/material/Tooltip';
import useMediaQuery from '@mui/material/useMediaQuery';
import { MouseEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ButtonNavigation,
  drawerProps,
  menuProps,
  MenuProps,
} from './MenusElements';
import { navItems } from './navigationItems';

export function NavigationMenu({ navTo, isAuth }: MenuProps) {
  const mobile = !useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const children = navItems.map(({ key, path, icon }) => (
    <MenuItem
      key={key}
      onClick={navTo(path)}
      disabled={!path}
      style={{ minHeight: '3.5rem' }}
    >
      <ListItemIcon>
        {!!icon && <SvgIcon component={icon} fontSize="small" />}
      </ListItemIcon>
      {t(key)}
    </MenuItem>
  ));

  const props = {
    id: 'navigation-menu',
    open,
    onClose: handleClose,
    onClick: handleClose,
    children,
  };

  return (
    <>
      <Tooltip title={t('menu.navigationLabel')}>
        <ButtonNavigation
          onClick={handleClick}
          aria-label={t('menu.navigationLabel')}
          aria-controls={open ? 'navigation-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
        />
      </Tooltip>

      {mobile ? (
        <Drawer {...props} {...drawerProps} />
      ) : (
        <Menu {...props} anchorEl={anchorEl} {...menuProps} />
      )}
    </>
  );
}
