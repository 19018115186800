import styled from '@mui/material/styles/styled';

import { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';

const SectionHeaderTitleShell = styled('header')(({ theme }) => ({
  fontSize: 34,
  fontFamily: 'Poppins',
  whiteSpace: 'break-spaces',
  marginTop: `-${theme.spacing(1.5)}`,
  marginBottom: `-${theme.spacing(1.5)}`,
  '& strong': {
    color: theme.palette.accent,
  },
}));

export interface SectionHeaderTitleProps {
  titleKey: string;
  accent: string;
}

export const SectionHeaderTitle: FC<SectionHeaderTitleProps> = ({
  titleKey,
  accent,
}) => {
  const { t } = useTranslation();
  return (
    <SectionHeaderTitleShell>
      <Trans i18nKey={titleKey} t={t}>
        {{ accent: t(accent) }}
      </Trans>
    </SectionHeaderTitleShell>
  );
};
