import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { AppTextField } from 'elements/AppTextField';
import { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';

export function PasswordFormForgotten({ isLoading }: { isLoading?: boolean }) {
  const { t } = useTranslation(undefined, { keyPrefix: 'password' });

  const [email, setEmail] = useState('');
  const onChange = (e: ChangeEvent<HTMLInputElement>) =>
    setEmail(e.target.value);

  return (
    <Box display="flex" gap={2} flexDirection="column">
      <Typography>{t('content')}</Typography>
      <AppTextField
        name="email"
        type="email"
        required
        fullWidth
        label={t('emailLabel')}
        onChange={onChange}
      />
      <Button
        variant="contained"
        color="secondary"
        fullWidth
        type="submit"
        disabled={isLoading || !email.length}
      >
        {t('action')}
      </Button>
    </Box>
  );
}
