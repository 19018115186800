import { styled } from '@mui/material/styles';
import { TWO_COLUMNS_BREAKPOINT } from './constants';

export const CommitteesSlide = styled('div')(({ theme }) => ({
  display: 'grid',
  justifyItems: 'center',
  gap: theme.spacing(4),
  width: `calc(100% - ${theme.spacing(6)})`,
  maxWidth: 444,
  marginInline: 'auto',
  paddingInline: theme.spacing(3),
  wordBreak: 'break-word',
  [theme.breakpoints.up(TWO_COLUMNS_BREAKPOINT)]: {
    maxWidth: theme.breakpoints.values.md,
    wordBreak: 'unset',
    gridTemplateColumns: 'repeat(auto-fit, minmax(min(360px, 100%), 1fr))',
  },
}));

export const CommitteesShell = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: theme.spacing(4),
  width: '100%',
  '& .swiper-wrapper': { marginBottom: theme.spacing(8) },
}));

export const CommitteesDetails = styled('div')(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  alignContent: 'center',
  gap: theme.spacing(4),
  width: '100%',
}));
