import type { Theme } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { AppSwipper } from 'elements/AppSwiper';
import { Contact } from 'elements/Contact';
import { SectionHeader } from 'elements/SectionHeader';
import { useBands } from 'hooks/useBands';
import { useUnions } from 'hooks/useUnions';
import { ReactElement, useMemo } from 'react';
import { useGetApiV3CommitteesQuery } from 'store/api';
import {
  CommitteesDetails,
  CommitteesShell,
  CommitteesSlide,
} from './CommitteesElements';
import { TWO_COLUMNS_BREAKPOINT } from './constants';

const getName = (...args: string[]) => args.filter(Boolean).join(' ');

interface CommitteesProps {
  listAll?: boolean;
}

export function Committees({ listAll }: CommitteesProps) {
  const [unions] = useUnions();
  const [hiddenUnionIds, setHiddenUnionids] = useBands('committees');
  const union = unions.find((u) => !hiddenUnionIds.includes(u.id))!;
  const unionId = union.id;
  const { data } = useGetApiV3CommitteesQuery({ unionId });
  const twoColumns = useMediaQuery((theme: Theme) =>
    theme.breakpoints.up(TWO_COLUMNS_BREAKPOINT)
  );

  const slides = useMemo(
    () => [
      ...chunks(
        (Array.isArray(data) ? data : [])?.map(
          (
            { position: header, firstName, lastName, ...rest }: any,
            i: number
          ) => (
            <Contact
              key={`${unionId}-${i}`}
              {...{
                ...rest,
                name: getName(firstName, lastName),
                header,
              }}
              fullWidth
            />
          )
        ),
        listAll ? Infinity : twoColumns ? 6 : 3
      ),
    ],
    [data, listAll, twoColumns, unionId]
  );

  const count = slides.length;

  return (
    <CommitteesShell>
      <SectionHeader
        titleKey="committees.title"
        accent="committees.overview"
        selected={[unionId]}
        unions={unions}
        sectionHeaderType={'unionBoardMembers'}
        handleClick={(id) => () =>
          setHiddenUnionids(unions.filter((u) => u.id !== id).map((u) => u.id))}
      />
      <CommitteesDetails key={unionId}>
        {count ? (
          <AppSwipper
            key={unionId}
            slides={slides}
            autoHeight
            loop={count > 1}
          />
        ) : null}
      </CommitteesDetails>
    </CommitteesShell>
  );
}

function* chunks(
  arr: ReactElement[] = [],
  n: number
): Generator<ReactElement, void> {
  for (let i = 0; i < arr.length; i += n) {
    yield <CommitteesSlide key={i}>{arr.slice(i, i + n)}</CommitteesSlide>;
  }
}
