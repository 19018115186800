import { Component, ErrorInfo, ReactElement, ReactNode } from 'react';

interface Props {
  children: ReactNode;
  logError?: (error: Error, errorInfo: ErrorInfo) => void;
  fallback?: ReactElement;
}

interface State {
  hasError: boolean;
}

export class ErrorBoundary extends Component<Props, State> {
  public static defaultProps = {
    logError: (error: Error, errorInfo: ErrorInfo) =>
      // eslint-disable-next-line no-console
      console.error('Uncaught error:', error, errorInfo),
  };

  public override state: State = {
    hasError: false,
  };

  public static getDerivedStateFromError(_: Error): State {
    return { hasError: true };
  }

  public override componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.props.logError?.(error, errorInfo);
  }

  public override render() {
    if (this.state.hasError) return this.props.fallback || null;
    return this.props.children;
  }
}

export function errorBoundary(
  elements: ReactElement | ReactElement[],
  fallback?: ReactElement
) {
  return Array.isArray(elements) ? (
    elements.map((e, i) => (
      <ErrorBoundary key={i} fallback={fallback}>
        {e}
      </ErrorBoundary>
    ))
  ) : (
    <ErrorBoundary fallback={fallback}>{elements}</ErrorBoundary>
  );
}
