import styled from '@mui/material/styles/styled';
import Typography from '@mui/material/Typography';
import { CSSProperties } from 'react';
import { useTranslation } from 'react-i18next';
import ImgFallback from './fallback.jpg';
import { PhotoZoom } from './PhotoZoom';

export const Author = styled(Typography)(({ theme }) => ({
  position: 'absolute',
  bottom: 0,
  right: 0,
  margin: theme.spacing(1.5),
  paddingInline: theme.spacing(1.25),
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.spacing(2),
}));

export interface PhotoProps {
  src?: string;
  alt?: string;
  author?: string;
  style?: CSSProperties;
  photoZoom?: boolean;
  hideFallbackImg?: boolean;
}

export const Photo = styled(
  ({
    alt,
    author,
    src,
    style,
    photoZoom,
    hideFallbackImg,
    ...rest
  }: PhotoProps) => {
    const { t } = useTranslation();
    return (
      <div role="img" aria-label={alt} {...rest}>
        {photoZoom && <PhotoZoom src={src} alt={alt} />}
        {author && <Author>{t('common.photo', { author })}</Author>}
      </div>
    );
  }
)<PhotoProps>(({ theme, style, src, hideFallbackImg }) => ({
  aspectRatio: '16 / 9',
  width: '100%',
  position: 'relative',
  lineHeight: 0,
  backgroundImage: `url(${!!src ? src : hideFallbackImg ? '' : ImgFallback})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  ...style,
}));
