import { BackButton } from 'elements/BackButton';
import { errorBoundary } from 'elements/ErrorBoundary';
import { Main } from 'elements/Main';
import { Contacts } from 'modules/Contacts';

export function ContactsPage() {
  return (
    <Main>
      <BackButton />
      {errorBoundary(<Contacts />)}
    </Main>
  );
}
