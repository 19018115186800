import { useAppDispatch } from 'hooks/useRedux';
import { useCallback } from 'react';
import { handleModalsLogin } from 'store/slices/modalsSlice';

export function useModalsLoginSet() {
  const dispatch = useAppDispatch();
  return useCallback(
    (state: boolean) => dispatch(handleModalsLogin(state)),
    [dispatch]
  );
}
