import { selectUnionById } from 'selectors';
import { useUnions } from './useUnions';
import { useUser } from './useUser';

export function useUnion() {
  const [user] = useUser();
  const [unions] = useUnions();
  const unionId = user?.unionId;
  return selectUnionById(unions, unionId);
}
