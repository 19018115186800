import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query/react';
import news from 'modules/News/newsSlice';
import search from 'modules/Search/searchSlice';
import topics from 'modules/Topics/topicsSlice';
import { FC, PropsWithChildren } from 'react';
import { Provider } from 'react-redux';
import { api } from './api';
import modals from './slices/modalsSlice';

const reducer = {
  [api.reducerPath]: api.reducer,
  news,
  search,
  topics,
  modals,
};

const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(api.middleware),
  devTools: {
    name: 'web-app',
  },
});

setupListeners(store.dispatch);

export const StoreProvider: FC<PropsWithChildren<{}>> = ({ children }) => (
  <Provider store={store}>{children}</Provider>
);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
