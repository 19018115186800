import styled from '@mui/material/styles/styled';
import useTheme from '@mui/material/styles/useTheme';
import { Map, Marker } from 'pigeon-maps';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

export const MapOsmShell = styled('div')(({ theme }) => ({
  width: '100%',
  [theme.breakpoints.down('md')]: {
    width: '100vw',
  },
}));

const Attribution = () => (
  <Fragment>
    {'© '}
    <a
      href="https://www.openstreetmap.org/copyright"
      target="_blank"
      rel="noopener noreferrer"
    >
      OpenStreetMap
    </a>
  </Fragment>
);

interface MapsProps {
  zoom: number;
  lat: number;
  lon: number;
}

export function MapOsm({ zoom = 15, lat, lon }: MapsProps) {
  const theme = useTheme();
  const { t } = useTranslation();

  if (!lat || !lon) return null;

  return (
    <MapOsmShell>
      <Map
        height={300}
        center={[lat, lon]}
        zoom={zoom}
        zoomSnap={false}
        attributionPrefix={false}
        attribution={<Attribution />}
        metaWheelZoom={true}
        metaWheelZoomWarning={t('common.mapsZoomMeta')}
      >
        <Marker
          width={42}
          anchor={[lat, lon]}
          color={theme.palette.primary.main}
        />
      </Map>
    </MapOsmShell>
  );
}
