import Typography from '@mui/material/Typography';
import { SectionHeader } from 'elements/SectionHeader';
import { Summary } from 'elements/Summary';
import { useModalsCommentSet } from 'hooks/useModalsCommentSet';
import { useModalsJoinNowSet } from 'hooks/useModalsJoinNowSet';
import { useNavigateTop } from 'hooks/useNavigateTop';
import { useReactions } from 'hooks/useReactions';
import { useUnions } from 'hooks/useUnions';
import { useUser } from 'hooks/useUser';
import { useTranslation } from 'react-i18next';
import { generatePath } from 'react-router-dom';
import { routes } from 'routes';
import { selectUserInformal } from 'selectors';
import { usePutApiV2ReactionsMutation } from 'store/api/apiFeedback';
import { NewsContent, NewsReadMore, NewsShell } from './NewsElements';
import { useNews } from './useNews';

interface NewsProps {
  showMore?: boolean;
}

export function News({ showMore }: NewsProps) {
  const { t, i18n } = useTranslation(undefined, { keyPrefix: 'news' });
  const navigateTop = useNavigateTop();

  const [user] = useUser();
  const informal = selectUserInformal(user);
  const [unions] = useUnions();

  const setModalsRegister = useModalsJoinNowSet();

  const reactions = useReactions();
  const [setLike] = usePutApiV2ReactionsMutation();
  const handleLike = (id: number) => (state: boolean) => () => {
    if (!user) return setModalsRegister(true);
    setLike({
      id,
      value: state === reactions[id] ? null : state,
    });
  };

  const setModalsComment = useModalsCommentSet();
  const handleComment = (id: number) => () => {
    if (!user) return setModalsRegister(true);
    setModalsComment(id);
  };

  const handleReadMore = (idOrUri: number | string) => () => {
    navigateTop(
      generatePath(routes.NEWS_ITEM, {
        idOrUri,
      })
    );
  };

  const {
    items,
    selected,
    handleLoadNextPage,
    handleToggleUnion,
    hasMorePages,
  } = useNews(unions);

  const list = showMore ? items.flat() : items.flat().slice(0, 4);

  return (
    <NewsShell>
      <SectionHeader
        titleKey="news.title"
        accent={
          informal ? 'approach.forYou.informal' : 'approach.forYou.formal'
        }
        selected={selected}
        unions={unions}
        sectionHeaderType={'news'}
        handleClick={handleToggleUnion}
      />
      <NewsContent>
        {list.length ? (
          list.map(({ id, customUrl, image, ...rest }) => (
            <Summary
              key={id}
              {...rest}
              image={image?.src?.length ? image : undefined}
              isLiked={reactions[id]}
              handleReadMore={handleReadMore(customUrl || id)}
              handleLike={handleLike(id)}
              handleComment={handleComment(id)}
            />
          ))
        ) : (
          <Typography variant="h6">{t('empty')}</Typography>
        )}
      </NewsContent>
      {showMore && (
        <NewsReadMore onClick={handleLoadNextPage} hidden={!hasMorePages}>
          {`${i18n.t('common.loadMore')}`}
        </NewsReadMore>
      )}
    </NewsShell>
  );
}
