import IconButton from '@mui/material/IconButton';
import { useNavigateTop } from 'hooks/useNavigateTop';
import { useUnion } from 'hooks/useUnion';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { routes } from 'routes';
import { selectUnionSocials } from 'selectors';
import { FooterShell, LegalLink, Social, SocialIcon } from './FooterElements';

export interface FooterProps {}

export const Footer: FC<FooterProps> = () => {
  const { t } = useTranslation(undefined, { keyPrefix: 'footer' });
  const socials = selectUnionSocials(useUnion());
  // const setModalsCookies = useModalsCookiesSet();
  const navigate = useNavigateTop();

  return (
    <FooterShell>
      <Social>
        {socials?.map(({ name, url }: any) => (
          <IconButton
            key={name}
            color="primary"
            aria-label={name}
            href={url}
            target="_blank"
            rel="noopener"
            size="large"
          >
            <SocialIcon name={name} />
          </IconButton>
        ))}
      </Social>
      <LegalLink onClick={() => navigate(routes.IMPRINT)}>
        {t('imprint')}
      </LegalLink>
      <LegalLink onClick={() => navigate(routes.PRIVACY)}>
        {t('privacy')}
      </LegalLink>
      {/* <LegalLink onClick={() => setModalsCookies(true)}>
        {t('cookies')}
      </LegalLink> */}
    </FooterShell>
  );
};
