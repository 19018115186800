import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store';
import { NewsItem } from 'store/api';

const DEFAULT_LIMIT = 10;
const PAGE_BASE = 1;

type NewsItems = { [hIds: string]: NewsItem[][] };

interface NewsState {
  items: NewsItems;
  page: { [hIds: keyof NewsItems]: number };
  total: { [hIds: keyof NewsItems]: number };
}

export type RequestByUnionId = {
  hUnionIds: string;
  page: number;
  limit?: number;
};

const h = (ids: number[]) => ids.join(',');

export const fetchNewsPage = createAsyncThunk(
  'news/fetch',
  async (
    { hUnionIds, page = PAGE_BASE, limit = DEFAULT_LIMIT }: RequestByUnionId,
    { getState }
  ) => {
    const state = getState() as RootState;
    const unionIds = hUnionIds.split(',');
    if (!unionIds.length) return;
    if (state.news.items[hUnionIds]?.[page] !== undefined) return;

    const searchParams = new URLSearchParams([
      ['PageIndex', page.toString()],
      ['PageSize', limit.toString()],
      ...unionIds.map((s) => ['unionId', s]),
    ]);

    try {
      const response = await fetch('/api/v3/news?' + searchParams);
      const data = await response.json();
      return data;
    } catch (err) {
      // TODO: handle errors
    }
  }
);

const initialState: NewsState = {
  items: {},
  page: {},
  total: {},
};

const newsSlice = createSlice({
  name: 'news',
  initialState,
  reducers: {
    nextPage(state, action: PayloadAction<{ unionIds: number[] }>) {
      state.page[h(action.payload.unionIds)]++;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchNewsPage.fulfilled, (state, action) => {
      if (action.payload === undefined) return;
      const items = action.payload.data?.items;
      if (!items) return;
      const { hUnionIds, page } = action.meta.arg;
      const total = action.payload.data?.totalPages;
      if (!state.items[hUnionIds]) state.items[hUnionIds] = [];
      state.items[hUnionIds][page] = items;
      state.page[hUnionIds] = page;
      state.total[hUnionIds] = total!;
    });
  },
});

export const { nextPage } = newsSlice.actions;

export default newsSlice.reducer;
