import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import { useModalsJoinNowSet } from 'hooks/useModalsJoinNowSet';
import { useNavigateTop } from 'hooks/useNavigateTop';
import { useUser } from 'hooks/useUser';
import { useTranslation } from 'react-i18next';
import { routes } from 'routes';

export const ReadAllButton = styled(Button)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
}));
ReadAllButton.defaultProps = { variant: 'contained' };

const buttonContent = {
  NEWS: 'news.readAll',
  TOPICS: 'topics.readAll',
  COMMITTEES: 'committees.readAll',
};

interface ReadAllProps {
  content: keyof typeof buttonContent;
}

export function ReadAll({ content }: ReadAllProps) {
  const { t } = useTranslation();
  const [user] = useUser();
  const setModalsRegister = useModalsJoinNowSet();
  const navigateTop = useNavigateTop();

  const handleReadAll = () => {
    if (!user) return setModalsRegister(true);
    navigateTop(routes[content]);
  };

  return (
    <ReadAllButton onClick={handleReadAll}>
      {t(buttonContent[content])}
    </ReadAllButton>
  );
}
