import MenuIcon from '@mui/icons-material/Menu';
import type { DrawerProps, MenuProps as MuiMenuProps } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import styled from '@mui/material/styles/styled';
import SvgIcon from '@mui/material/SvgIcon';
import { ReactComponent as AccountChecked } from './icons/account-checked.svg';

export interface MenuProps {
  navTo: (path?: string) => () => void;
  isAuth?: boolean;
}

export const drawerProps: Partial<DrawerProps> = {
  anchor: 'top',
  PaperProps: {
    sx: {
      borderBottomLeftRadius: 12,
      borderBottomRightRadius: 12,
    },
  },
};

export const menuProps: Partial<MuiMenuProps> = {
  transformOrigin: { horizontal: 'right', vertical: 'top' },
  anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
  PaperProps: {
    elevation: 0,
    sx: {
      overflow: 'visible',
      filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
      mt: 1.5,
      paddingInline: 2,
      paddingBlock: 1,
      '&:before': {
        content: '""',
        display: 'block',
        position: 'absolute',
        top: 0,
        right: 9,
        width: 10,
        height: 10,
        bgcolor: 'background.paper',
        transform: 'translateY(-50%) rotate(45deg)',
        zIndex: 0,
      },
    },
  },
};

export const ButtonAccount = styled(IconButton)(({ theme }) => ({
  lineHeight: 0,
  padding: 0,
}));
ButtonAccount.defaultProps = {
  children: (
    <SvgIcon>
      <AccountChecked />
    </SvgIcon>
  ),
  color: 'primary',
};

export const ButtonNavigation = styled(IconButton)(({ theme }) => ({
  lineHeight: 0,
  padding: 0,
  borderRadius: 0,
  '& span span.MuiTouchRipple-child': {
    borderRadius: 0,
  },
  display: 'block',
  [theme.breakpoints.up('md')]: {
    display: 'none',
  },
}));
ButtonNavigation.defaultProps = {
  children: <MenuIcon style={{ fontSize: 28 }} />,
  color: 'primary',
};

export const Accent = styled('span')(({ theme }) => ({
  color: theme.palette.accent,
}));
