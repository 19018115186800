import IconButton from '@mui/material/IconButton';
import styled from '@mui/material/styles/styled';
import SvgIcon from '@mui/material/SvgIcon';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Comment } from './comment.svg';
import { ReactComponent as Dislike } from './dislike.svg';
import { ReactComponent as Like } from './like.svg';

const FeedbackShell = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(2),
}));

export interface FeedbackProps {
  isLiked?: boolean | null;
  handleLike: (like: boolean) => () => void;
  handleComment: () => void;
}

export const Feedback: FC<FeedbackProps> = ({
  isLiked,
  handleLike,
  handleComment,
}) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'feedback' });

  return (
    <FeedbackShell>
      <IconButton
        color={isLiked === true ? 'primary' : 'secondary'}
        aria-label={t('like')}
        onClick={handleLike(true)}
      >
        <SvgIcon>
          <Like />
        </SvgIcon>
      </IconButton>

      <IconButton
        color={isLiked === false ? 'primary' : 'secondary'}
        aria-label={t('dislike')}
        onClick={handleLike(false)}
      >
        <SvgIcon>
          <Dislike />
        </SvgIcon>
      </IconButton>

      <IconButton
        color="secondary"
        aria-label={t('comment')}
        onClick={handleComment}
      >
        <SvgIcon>
          <Comment />
        </SvgIcon>
      </IconButton>
    </FeedbackShell>
  );
};
