import Button from '@mui/material/Button';
import styled from '@mui/material/styles/styled';
import { FC, MouseEventHandler } from 'react';
import { Union } from 'store/api';

const SectionHeaderMenuShell = styled('div')(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  gap: theme.spacing(2.5),
  alignContent: 'end',
  justifyContent: 'end',
  [theme.breakpoints.down('md')]: {
    flexWrap: 'nowrap',
    alignContent: 'unset',
    justifyContent: 'unset',
    overflowX: 'scroll',
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    marginLeft: theme.spacing(-3),
    marginRight: theme.spacing(-3),
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': {
      width: 0,
    },
  },
}));

const SectionHeaderMenuButton = styled(Button)(() => ({
  borderRadius: 24,
  whiteSpace: 'nowrap',
  minWidth: 'max-content',
}));
SectionHeaderMenuButton.defaultProps = { variant: 'contained' };

export interface SectionHeaderMenuProps {
  unions: { id: number; nameShort: string; isVisible: Union['isVisible'] }[];
  selected: (string | number)[];
  sectionHeaderType?: keyof Union['isVisible'];
  handleClick: (id: number) => MouseEventHandler<HTMLButtonElement>;
}

export const SectionHeaderMenu: FC<SectionHeaderMenuProps> = ({
  unions,
  selected,
  sectionHeaderType,
  handleClick,
}) => {
  return (
    <SectionHeaderMenuShell>
      {unions.map(({ id, nameShort, isVisible }) => {
        return sectionHeaderType && isVisible[sectionHeaderType] ? (
          <SectionHeaderMenuButton
            key={id}
            onClick={handleClick(id)}
            color={selected.includes(id) ? 'primary' : 'secondary'}
          >
            {nameShort}
          </SectionHeaderMenuButton>
        ) : null;
      })}
    </SectionHeaderMenuShell>
  );
};
