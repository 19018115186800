import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Fab from '@mui/material/Fab';
import Slide from '@mui/material/Slide';
import useScrollTrigger from '@mui/material/useScrollTrigger';

export function ScrollToTop() {
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: window.innerHeight,
  });

  return (
    <Slide appear={false} direction="up" in={trigger}>
      <Fab
        size="medium"
        color="primary"
        aria-label="scroll to top"
        onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
        sx={(theme) => ({
          backgroundColor: theme.palette.background.paper,
          '&:hover': {
            backgroundColor: theme.palette.grey[100],
          },
          boxShadow: '0px 2px 8px 0px rgb(0 0 0 / 0.3)',
          position: 'fixed',
          bottom: 0,
          right: 0,
          margin: 3,
        })}
      >
        <ArrowForwardIosIcon
          sx={(theme) => ({
            color: theme.palette.primary.main,
            transform: 'rotate(-90deg) scale(1.6)',
            marginTop: '-3px',
          })}
        />
      </Fab>
    </Slide>
  );
}
