import { CSSProperties } from 'react';
import { BannerShellImageOnly, OverlayLink } from '../BannerElements';

interface TemplateImageOnlyProps {
  img?: string | null;
  link?: string | null;
  style?: CSSProperties;
}

export function TemplateImageOnly({
  img,
  link,
  style,
}: TemplateImageOnlyProps) {
  return (
    <BannerShellImageOnly style={style}>
      <div>
        {!!img && <img src={img} alt="" role="presentation" />}
        {!!link && (
          <OverlayLink href={link} target="_blank" rel="noreferrer">
            {link}
          </OverlayLink>
        )}
      </div>
    </BannerShellImageOnly>
  );
}
