import styled from '@mui/material/styles/styled';
import { useTranslation } from 'react-i18next';

export const PasswordTitle = styled(
  ({ phase, ...rest }: { phase?: number }) => {
    const { t } = useTranslation(undefined, { keyPrefix: 'password' });
    return phase === 1 ? (
      <span {...rest}>
        <span>{t('title1')}</span> {t('title2')}
      </span>
    ) : phase === 2 ? (
      <span {...rest}>
        <span>{t('title1')}</span> {t('title2')} {t('title3')}
      </span>
    ) : (
      <span {...rest}>
        <span>{t('title2')}</span> {t('title4')}
      </span>
    );
  }
)(({ theme }) => ({
  marginBlock: 0,
  fontSize: 30,
  '& span': {
    color: theme.palette.primary.main,
  },
}));
