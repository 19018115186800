import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

export const Main = styled(Box)<{
  size?: 'sm' | 'md' | 'lg';
  gap?: number;
  block?: boolean;
}>(({ theme, size = 'lg', gap, block }) => ({
  display: block ? 'block' : 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: theme.spacing(gap ?? 3),
  maxWidth: `min(calc(100vw - ${theme.spacing(6)}), ${
    theme.breakpoints.values[size]
  }px)`,
  marginTop: theme.spacing(4),
  marginBottom: theme.spacing(4),
  marginRight: 'auto',
  marginLeft: 'auto',
}));
Main.defaultProps = { component: 'main' };
