import Typography from '@mui/material/Typography';
import { SectionHeader } from 'elements/SectionHeader';
import { Summary } from 'elements/Summary';
import { useModalsCommentSet } from 'hooks/useModalsCommentSet';
import { useModalsJoinNowSet } from 'hooks/useModalsJoinNowSet';
import { useNavigateTop } from 'hooks/useNavigateTop';
import { useReactions } from 'hooks/useReactions';
import { useUnions } from 'hooks/useUnions';
import { useUser } from 'hooks/useUser';
import { useTranslation } from 'react-i18next';
import { generatePath } from 'react-router-dom';
import { routes } from 'routes';
import { selectUserInformal } from 'selectors';
import { usePutApiV2ReactionsMutation } from 'store/api/apiFeedback';
import { TopicsContent, TopicsReadMore, TopicsShell } from './TopicsElements';
import { useTopics } from './useTopics';

interface TopicsProps {
  showMore?: boolean;
}

export function Topics({ showMore }: TopicsProps) {
  const { t, i18n } = useTranslation(undefined, { keyPrefix: 'topics' });
  const navigateTop = useNavigateTop();

  const [user] = useUser();
  const informal = selectUserInformal(user);
  const [unions] = useUnions();

  const setModalsRegister = useModalsJoinNowSet();

  const reactions = useReactions();
  const [setLike] = usePutApiV2ReactionsMutation();
  const handleLike = (id: number) => (state: boolean) => () => {
    if (!user) return setModalsRegister(true);
    setLike({
      id,
      value: state === reactions[id] ? null : state,
    });
  };

  const setModalsComment = useModalsCommentSet();
  const handleComment = (id: number) => () => {
    if (!user) return setModalsRegister(true);
    setModalsComment(id);
  };

  const handleReadMore = (idOrUri: string | number) => () => {
    navigateTop(
      generatePath(routes.TOPICS_ITEM, {
        idOrUri,
      })
    );
  };

  const {
    items,
    selected,
    handleLoadNextPage,
    handleToggleUnion,
    hasMorePages,
  } = useTopics(unions);

  const list = showMore ? items.flat() : items.flat().slice(0, 4);

  return (
    <TopicsShell>
      <SectionHeader
        titleKey="topics.title"
        accent={
          informal ? 'approach.forYou.informal' : 'approach.forYou.formal'
        }
        selected={selected}
        unions={unions}
        sectionHeaderType={'topics'}
        handleClick={handleToggleUnion}
      />
      <TopicsContent>
        {list.length ? (
          list.map(({ id, customUrl, summary, ...rest }) => (
            <Summary
              key={id}
              {...rest}
              isLiked={reactions[id]}
              handleReadMore={handleReadMore(customUrl || id)}
              handleLike={handleLike(id)}
              handleComment={handleComment(id)}
            />
          ))
        ) : (
          <Typography variant="h6">{t('empty')}</Typography>
        )}
      </TopicsContent>
      {showMore && (
        <TopicsReadMore onClick={handleLoadNextPage} hidden={!hasMorePages}>
          {`${i18n.t('common.loadMore')}`}
        </TopicsReadMore>
      )}
    </TopicsShell>
  );
}
