import Box from '@mui/material/Box';

export function ExampleNotification() {
  return (
    <Box
      sx={(theme) => ({
        display: 'flex',
        gap: theme.spacing(0.5),
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: theme.palette.grey[300],
        borderRadius: theme.spacing(0.5),
        padding: theme.spacing(1),
        boxShadow: '1px 3px 9px #00000029',
      })}
    >
      <img height={36} width={36} src="/logos/192.png" alt="logo" />
      <Box
        style={{
          display: 'grid',
          gridTemplateRows: '1fr 1fr 1fr 1fr',
          width: '100%',
          gap: 4,
        }}
      >
        {Array(4)
          .fill(null)
          .map((_, i) => (
            <Box
              key={i}
              sx={(theme) => ({
                background: theme.palette.grey[200],
              })}
            ></Box>
          ))}
      </Box>
    </Box>
  );
}
