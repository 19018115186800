import type { Theme } from '@mui/material';
import Drawer from '@mui/material/Drawer';
import ListItemIcon from '@mui/material/ListItemIcon';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import SvgIcon from '@mui/material/SvgIcon';
import Tooltip from '@mui/material/Tooltip';
import useMediaQuery from '@mui/material/useMediaQuery';
import { MouseEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { routes } from 'routes';
import { AccountMenuHeader } from './AccountMenuHeader';
import { ReactComponent as Logout } from './icons/logout.svg';
import { ReactComponent as Profile } from './icons/profile.svg';
import { ReactComponent as Union } from './icons/union.svg';
import {
  ButtonAccount,
  drawerProps,
  menuProps,
  MenuProps,
} from './MenusElements';

const accItems = [
  { key: 'menu.myProfile', path: routes.PROFILE, icon: Profile },
  { key: 'menu.myUnion', path: routes.PROFILE_UNION, icon: Union },
  { key: 'menu.logout', path: routes.LOGOUT, icon: Logout },
];

export function AccountMenu({ navTo, isAuth }: MenuProps) {
  const mobile = !useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  if (!isAuth) return null;

  const children = [
    <AccountMenuHeader key="header" mobile={mobile} />,
    ...accItems.map(({ key, path, icon }) => (
      <MenuItem key={key} onClick={navTo(path)} style={{ minHeight: '3.5rem' }}>
        <ListItemIcon>
          <SvgIcon component={icon} fontSize="small" />
        </ListItemIcon>
        {t(key)}
      </MenuItem>
    )),
  ];

  const props = {
    id: 'account-menu',
    open,
    onClose: handleClose,
    onClick: handleClose,
    children,
  };

  return (
    <>
      <Tooltip title={t('menu.accountLabel')}>
        <ButtonAccount
          onClick={handleClick}
          aria-label={t('menu.accountLabel')}
          aria-controls={open ? 'account-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
        />
      </Tooltip>
      {mobile ? (
        <Drawer {...props} {...drawerProps} />
      ) : (
        <Menu {...props} anchorEl={anchorEl} {...menuProps} />
      )}
    </>
  );
}
