import { createApi } from '@reduxjs/toolkit/query/react';
import { Article } from 'data/schemas/Article';
import { axiosBaseQuery } from './axiosBaseQuery';
import type { ContentRelated, Union } from './types';

export const api = createApi({
  baseQuery: axiosBaseQuery(),
  endpoints: (build) => ({
    getApiV2Unions: build.query<Union[], undefined>({
      query: () => ({ url: `/api/v2/unions` }),
      transformResponse(baseQueryReturnValue, meta, arg) {
        return (baseQueryReturnValue as any).data;
      },
    }),

    getApiV1ContentGetContentByUnionId: build.query({
      query: (queryArg) => ({
        url: `/api/v1/Content/GetContentByUnionId`,
        params: {
          UnionId: queryArg.unionId,
          dateSpecific: queryArg.dateSpecific,
        },
      }),
      transformResponse(baseQueryReturnValue) {
        return (baseQueryReturnValue as any).data;
      },
    }),

    getApiV1SliderForVisitor: build.query({
      query: () => ({ url: `/api/v1/Slider/GetSliderForVisitor` }),
      transformResponse(baseQueryReturnValue, meta, arg) {
        return (baseQueryReturnValue as any).data;
      },
    }),
    getApiV1SliderForCurrentUser: build.query({
      query: () => ({ url: `/api/v1/Slider/GetSliderForCurrentUser` }),
      transformResponse(baseQueryReturnValue, meta, arg) {
        return (baseQueryReturnValue as any).data;
      },
    }),

    getApiV3Committees: build.query({
      query: (queryArg) => ({
        url: `/api/v3/committees`,
        params: {
          unionId: queryArg.unionId,
        },
      }),
      transformResponse(baseQueryReturnValue) {
        return (baseQueryReturnValue as any).data;
      },
    }),

    getApiV3Disruptor: build.query({
      query: () => ({
        url: `/api/v3/disruptor`,
      }),
      transformResponse(baseQueryReturnValue) {
        return (baseQueryReturnValue as any).data;
      },
    }),

    getApiV2Content: build.query({
      query: ({ id }) => ({
        url: `/api/v2/content/${id}`,
      }),
    }),

    getApiV3ArticleByIdOrUri: build.query<Article, { idOrUri: string }>({
      query: ({ idOrUri }) => ({
        url: `/api/v3/articles/${idOrUri}`,
      }),
      transformResponse(baseQueryReturnValue) {
        return (baseQueryReturnValue as any).data;
      },
    }),

    getApiV2ContentRelated: build.query<ContentRelated[], unknown>({
      query: ({ id }) => ({
        url: `/api/v2/content/related/${id}`,
      }),
      transformResponse(baseQueryReturnValue) {
        return (baseQueryReturnValue as any).data;
      },
    }),
  }),
}).enhanceEndpoints({
  addTagTypes: ['Auth'],
  endpoints: {
    getApiV2Unions: {
      providesTags: ['Auth'],
    },
    getApiV1ContentGetContentByUnionId: {
      providesTags: ['Auth'],
    },
    getApiV1SliderForCurrentUser: {
      providesTags: ['Auth'],
    },
  },
});

export const {
  endpoints: {
    getApiV2Unions: { useQueryState: useGetApiV2UnionsQueryState },
  },
  useGetApiV2UnionsQuery,

  useGetApiV1ContentGetContentByUnionIdQuery,

  useGetApiV1SliderForCurrentUserQuery,
  useGetApiV1SliderForVisitorQuery,

  useGetApiV3CommitteesQuery,

  useGetApiV3DisruptorQuery,

  useGetApiV3ArticleByIdOrUriQuery,
  useGetApiV2ContentQuery,
  useGetApiV2ContentRelatedQuery,
} = api;
