import { AppDialog } from 'elements/AppDialog';
import { useModals } from 'hooks/useModals';
import { useModalsJoinNowSet } from 'hooks/useModalsJoinNowSet';
import { useModalsLoginSet } from 'hooks/useModalsLoginSet';
import { JoinNowActions } from 'modules/LoginDialog/JoinNowActions';
import { JoinNowTitle } from 'modules/LoginDialog/JoinNowTitle';
import { FormEventHandler, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { routes } from 'routes';
import { usePostApiV2AuthLoginMutation } from 'store/api';
import { LoginActions } from './LoginActions';
import { LoginErrorMessage } from './LoginErrorMessage';
import { LoginForm } from './LoginForm';
import { LoginTitle } from './LoginTitle';

export function LoginDialog() {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const modals = useModals();
  const setModalsLogin = useModalsLoginSet();
  const setModalsJoinNow = useModalsJoinNowSet();

  const [showLoginForm, setShowLoginForm] = useState(false);
  const [loginError, setLoginError] = useState(false);

  const [loginUser, { isLoading }] = usePostApiV2AuthLoginMutation();

  const handleSubmit: FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    const email = formData.get('email');
    const password = formData.get('password');

    if (
      !email ||
      typeof email !== 'string' ||
      !password ||
      typeof password !== 'string'
    )
      return setLoginError(true);

    try {
      const res = await loginUser({
        body: { email, password },
      }).unwrap();

      if ((res as any)?.succeded !== false) {
        handleClose();
      } else {
        throw new Error('NOT_SUCCESS');
      }
    } catch {
      return setLoginError(true);
    }
  };

  useEffect(() => {
    if (modals.login || modals.joinNow) setShowLoginForm(false);
  }, [modals.login, modals.joinNow]);

  const handleClose = () => {
    setModalsLogin(false);
    setModalsJoinNow(false);
  };

  const handleRegister = () => {
    navigate(routes.REGISTER);
    handleClose();
  };

  const handleShowLoginForm = () => setShowLoginForm(true);

  return (
    <>
      <AppDialog
        open={modals.joinNow}
        onClose={handleClose}
        hideCloseButton
        handleSubmit={handleSubmit}
        style={{ textAlign: 'center' }}
        maxWidth="xs"
        scale={2}
        heading={<JoinNowTitle />}
        content={
          showLoginForm ? (
            <>
              <LoginForm
                handleRegister={handleRegister}
                isLoading={isLoading}
              />
              <LoginErrorMessage
                open={loginError}
                onClose={() => setLoginError(false)}
              />
            </>
          ) : (
            t('joinNow.content')
          )
        }
        actions={
          !showLoginForm && (
            <JoinNowActions
              {...{
                handleShowLoginForm,
                handleRegister,
              }}
            />
          )
        }
      />
      <AppDialog
        open={modals.login}
        onClose={handleClose}
        hideCloseButton
        handleSubmit={handleSubmit}
        style={{ textAlign: 'center' }}
        maxWidth="xs"
        scale={2}
        heading={<LoginTitle />}
        content={
          showLoginForm && (
            <>
              <LoginForm
                handleRegister={handleRegister}
                isLoading={isLoading}
              />
              <LoginErrorMessage
                open={loginError}
                onClose={() => setLoginError(false)}
              />
            </>
          )
        }
        actions={
          !showLoginForm && (
            <LoginActions
              {...{
                handleShowLoginForm,
                handleRegister,
              }}
            />
          )
        }
      />
    </>
  );
}
