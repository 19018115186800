import { useUser } from 'hooks/useUser';
import { FC } from 'react';
import { HeroUser } from './HeroUser';
import { HeroVisitor } from './HeroVisitor';

export const Hero: FC = () => {
  const [user] = useUser();

  return user ? <HeroUser /> : <HeroVisitor />;
};
