import LiveSearchBar from './LiveSearchBar';
import LiveSearchResults from './LiveSearchResults';
import { SearchWrapper } from './SearchElements';
import { useSearch } from './useSearch';

export function LiveSearch() {
  const {
    showLiveSearch,
    liveSearchItems,
    liveSearchTerm,
    totalLiveSearchItems,
    showLiveSearchResults,
    handleFetchingSearchResults,
    handleSearchTermChange,
    handleShowLiveSearchResults,
    handleInitDataForSearchPage,
  } = useSearch();

  return (
    <>
      {showLiveSearch && (
        <SearchWrapper>
          <LiveSearchBar
            handleFetchingSearchResults={handleFetchingSearchResults}
            handleSearchTermChange={handleSearchTermChange}
            handleShowLiveSearchResults={handleShowLiveSearchResults}
            handleInitDataForSearchPage={handleInitDataForSearchPage}
            searchTerm={liveSearchTerm}
          />
          {showLiveSearchResults && liveSearchItems && (
            <LiveSearchResults
              results={liveSearchItems}
              handleSearchTermChange={handleSearchTermChange}
              handleShowLiveSearchResults={handleShowLiveSearchResults}
              totalLiveSearchItems={totalLiveSearchItems}
              handleInitDataForSearchPage={handleInitDataForSearchPage}
            />
          )}
        </SearchWrapper>
      )}
    </>
  );
}
