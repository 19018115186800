import { Banner } from 'elements/Banner';
import { errorBoundary } from 'elements/ErrorBoundary';
import { Main } from 'elements/Main';
import { isAuth } from 'helpers/isAuth';
import { useBanner } from 'hooks/useBanner';
import { CommentDialog } from 'modules/CommentDialog';
import { Committees } from 'modules/Committees';
import { Contacts } from 'modules/Contacts';
import { Footer } from 'modules/Footer';
import { Hero } from 'modules/Hero';
import { News } from 'modules/News';
import { ReadAll } from 'modules/ReadAll';
import { Topics } from 'modules/Topics';
import { lazy } from 'react';

const ServiceAndDownloads = lazy(() =>
  import('modules/ServicesAndDownloads').then((res) => ({
    default: res.ServicesAndDownloads,
  }))
);

export function HomePage() {
  const banners = useBanner(3);

  return (
    <>
      <Main gap={8} style={{ backgroundColor: 'transparent' }}>
        {errorBoundary([
          <Hero />,
          <>
            <News />
            <ReadAll content="NEWS" />
          </>,
          <Banner banner={banners[0]} />,
          <>
            <Topics />
            <ReadAll content="TOPICS" />
          </>,
          <Banner banner={banners[1]} />,
          <>
            <Committees />
            <ReadAll content="COMMITTEES" />
          </>,
          <>{isAuth() && <ServiceAndDownloads />}</>,
          <Contacts />,
          <Banner banner={banners[2]} />,
        ])}
      </Main>
      {errorBoundary([<Footer />, <CommentDialog />])}
    </>
  );
}
