import IconChecked from '@mui/icons-material/CheckCircle';
import IconUnchecked from '@mui/icons-material/RadioButtonUnchecked';
import Checkbox, { CheckboxProps } from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';

export function PasswordValid({
  label,
  checked,
  ...rest
}: CheckboxProps & { label: string }) {
  return (
    <FormControlLabel
      control={
        <Checkbox
          disabled
          {...rest}
          size="small"
          checked={checked}
          icon={<IconUnchecked color="error" />}
          checkedIcon={<IconChecked color="success" />}
        />
      }
      label={
        <Typography
          sx={(theme) => ({
            color: checked
              ? theme.palette.success.main
              : theme.palette.error.main,
          })}
        >
          {label}
        </Typography>
      }
    />
  );
}
