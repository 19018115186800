import styled from '@mui/material/styles/styled';
import { FC } from 'react';
import { SectionHeaderMenu, SectionHeaderMenuProps } from './SectionHeaderMenu';
import {
  SectionHeaderTitle,
  SectionHeaderTitleProps,
} from './SectionHeaderTitle';

const SectionHeaderShell = styled('header')(({ theme }) => ({
  width: '100%',
  fontWeight: 'bold',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  gap: theme.spacing(2.5),
  marginTop: theme.spacing(5),
  marginBottom: theme.spacing(3),
  [theme.breakpoints.down('md')]: {
    flexWrap: 'wrap',
  },
}));

interface SectionHeaderProps
  extends SectionHeaderMenuProps,
    SectionHeaderTitleProps {}

export const SectionHeader: FC<SectionHeaderProps> = ({
  titleKey,
  accent,
  ...rest
}) => {
  return (
    <SectionHeaderShell>
      <SectionHeaderTitle {...{ titleKey, accent }} />
      <SectionHeaderMenu {...rest} />
    </SectionHeaderShell>
  );
};
