import { useEffect, useRef } from 'react';

export function useResizeObserver<T extends HTMLElement>(
  callback: ResizeObserverCallback
) {
  const ref = useRef<T>(null);

  useEffect(() => {
    if (!ResizeObserver) return;
    const observer = new ResizeObserver(callback);
    if (ref.current) observer.observe(ref.current);
    return () => observer.disconnect();
  }, [callback]);

  return ref;
}
