import { api } from './api';

export type Banner = {
  templateId: number;
  highlightedText?: string;
  text?: string;
  cta?: string;
  link?: string;
  imgSrc?: string;
  animation?: string;
};

export const apiBanner = api.injectEndpoints({
  endpoints: (build) => ({
    getApiV2BannersRandomList: build.query<Banner[], number>({
      query: (numberOfBanners) => ({
        url: `/api/v2/banners/random/list?number=${numberOfBanners}`,
      }),
      keepUnusedDataFor: 5,
      transformResponse(baseQueryReturnValue) {
        return (baseQueryReturnValue as any).data;
      },
    }),
  }),
});

export const { useGetApiV2BannersRandomListQuery } = apiBanner;
