import { styled } from '@mui/material/styles';
import { ReactElement } from 'react';
import { Autoplay, Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';
import { Swiper, SwiperProps, SwiperSlide } from 'swiper/react';

export const AppSwipper = styled(
  ({ slides, ...rest }: SwiperProps & { slides: ReactElement[] }) => {
    return (
      <Swiper
        modules={[Pagination, Autoplay]}
        pagination={{ clickable: true }}
        loop={true}
        threshold={10}
        {...rest}
      >
        {slides.map((e, i) => (
          <SwiperSlide key={i}>{e}</SwiperSlide>
        ))}
      </Swiper>
    );
  }
)(({ theme }) => ({
  width: '100%',
  cursor: 'default',
  '.swiper-pagination-bullet': {
    width: theme.spacing(1.5),
    height: theme.spacing(1.5),
    backgroundColor: theme.palette.secondary.main,
  },
  '.swiper-pagination-bullet-active': {
    width: theme.spacing(1.5),
    height: theme.spacing(1.5),
    backgroundColor: theme.palette.primary.main,
  },
  // prevent flickering in Safari
  // https://github.com/nolimits4web/swiper/issues/3527
  '.swiper-slide': {
    WebkitTransform: 'translate3d(0, 0, 0)',
  },
}));
