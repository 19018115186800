import Button from '@mui/material/Button';
import styled from '@mui/material/styles/styled';
import { AppSwipper } from 'elements/AppSwiper';
import { FC } from 'react';

const HeroShell = styled('div')(({ theme }) => ({
  marginTop: '-' + theme.spacing(4),
  maxWidth: `min(100%, ${theme.breakpoints.values.lg}px)`,
}));

const HeroContent = styled('div')<{ 'data-src': string }>(
  ({ theme, 'data-src': src }) => ({
    backgroundImage: `url(${src})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    marginBottom: theme.spacing(6),
    paddingInline: theme.spacing(3),
    paddingBlock: theme.spacing(4),
    minHeight: 500,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    gap: theme.spacing(5),
    alignItems: 'center',
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(10),
      paddingInline: theme.spacing(10),
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
    },
  })
);

const HeroBox = styled('div')(({ theme }) => ({
  position: 'relative',
  maxWidth: theme.breakpoints.values.md,
  borderRadius: theme.spacing(1),
  overflow: 'hidden',
  paddingInline: theme.spacing(2),
  paddingBlock: theme.spacing(3),
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  width: '100%',
  '&::before': {
    content: '""',
    display: 'block',
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    opacity: 0.7,
    backgroundColor: theme.palette.primary.contrastText,
  },
  [theme.breakpoints.up('md')]: {
    paddingInline: theme.spacing(3),
    paddingBlock: theme.spacing(4),
  },
}));

const HeroTitle = styled('div')(({ theme }) => ({
  color: theme.palette.text.primary,
  fontWeight: 'bold',
  fontSize: 22,
  fontFamily: 'Poppins',
  zIndex: 1,
  [theme.breakpoints.up('sm')]: {
    fontSize: 30,
  },
}));

const HeroText = styled('div')(({ theme }) => ({
  color: theme.palette.text.primary,
  fontFamily: 'Poppins',
  fontSize: 16,
  zIndex: 1,
  [theme.breakpoints.up('sm')]: {
    fontSize: 20,
  },
}));

const HeroButton = styled(Button)(({ theme }) => ({
  maxWidth: theme.breakpoints.values.md,
  [theme.breakpoints.up('md')]: {
    width: `min(50%, ${theme.breakpoints.values.md / 2}px)`,
  },
}));
HeroButton.defaultProps = { variant: 'contained' };

export interface HeroElementProps {
  images?: {
    src: string;
    title?: string;
    text?: string;
    button?: string;
    onClick: () => void;
  }[];
}

export const HeroElement: FC<HeroElementProps> = ({ images }) => {
  if (!images?.length) return null;

  return (
    <HeroShell>
      <AppSwipper
        autoplay={true}
        slides={images.map(({ src, title, text, onClick, button }) => (
          <HeroContent data-src={src}>
            <HeroBox>
              <HeroTitle>{title}</HeroTitle>
              <HeroText>{text}</HeroText>
            </HeroBox>
            <HeroButton onClick={onClick} fullWidth>
              {button}
            </HeroButton>
          </HeroContent>
        ))}
      />
    </HeroShell>
  );
};
