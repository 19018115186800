import { useMatomo } from '@jonkoops/matomo-tracker-react';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { errorBoundary as eb } from 'elements/ErrorBoundary';
import { ScrollToTop } from 'elements/ScrollToTop';
import { isAuth } from 'helpers/isAuth';
import { useUnion } from 'hooks/useUnion';
import { useUser } from 'hooks/useUser';
import ErrorDialog from 'modules/ErrorDialog/ErrorDialog';
import { Header } from 'modules/Header';
import { LoginDialog } from 'modules/LoginDialog';
import { Notifications } from 'modules/Notifications';
import { PasswordDialog } from 'modules/PasswordDialog';
import { LiveSearch } from 'modules/Search/LiveSearch';
import { CommitteesPage } from 'pages/CommitteesPage';
import { ContactsPage } from 'pages/ContactsPage';
import { HomePage } from 'pages/HomePage';
import { NewsPage } from 'pages/NewsPage';
import { NotFoundPage } from 'pages/NotFoundPage';
import { OoupsPage } from 'pages/OoupsPage';
import { TopicsPage } from 'pages/TopicsPage';
import {
  lazy,
  PropsWithChildren,
  ReactElement,
  Suspense,
  useEffect,
  useMemo,
} from 'react';
import { Navigate, Outlet, Route, Routes, useLocation } from 'react-router-dom';
import { routes } from 'routes';
import { selectUnionTheme } from 'selectors/unions';
import { domain } from 'services/cookies';
import { matomoDisabled } from 'services/matomo';
import { useGetApiV1AccountGetCurrentUserDataQuery } from 'store/api';
import { useGetApiV2UnionsQuery } from 'store/api/api';
import { useGetApiV2AccountPreferencesQuery } from 'store/api/apiPreferences';
import { getTheme } from 'styles';

const RegisterPage = lazy(() => import('pages/RegisterPage'));
const ProfilePage = lazy(() => import('pages/ProfilePage'));
const LogoutPage = lazy(() => import('pages/LogoutPage'));
const ImprintPage = lazy(() => import('pages/ImprintPage'));
const PrivacyPage = lazy(() => import('pages/PrivacyPage'));
const GdprPage = lazy(() => import('pages/GdprPage'));
const ConfirmEmailPage = lazy(() => import('pages/ConfirmEmailPage'));
const ForgotPasswordPage = lazy(() => import('pages/ForgotPasswordPage'));
const ArticlePage = lazy(() => import('pages/ArticlePage'));
const ArticlePreview = lazy(() => import('modules/Article/ArticlePreview'));
const SearchPage = lazy(() => import('pages/SearchPage'));
const ServicesAndDownloadsPage = lazy(
  () => import('pages/ServicesAndDownloadsPage')
);
const DemoPage = lazy(() =>
  import('pages/DemoPage').then((res) => ({
    default: res.HomePage,
  }))
);

function Theming({ children }: PropsWithChildren<{}>) {
  const unionTheme = selectUnionTheme(useUnion());
  const theme = useMemo(() => getTheme(unionTheme), [unionTheme]);
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
}

function RoutesWithHeader() {
  const [user] = useUser();

  // TODO: improve
  // Redirect if on sub-domain and authorized
  useEffect(() => {
    if (
      window.location.hostname !== 'localhost' &&
      !!user &&
      window.location.hostname.split('.')[0] !== 'www'
    ) {
      window.location.replace(['https://www', domain].join('.'));
    }
  }, [user]);

  return (
    <>
      {eb(<Header />)}
      {eb(<LiveSearch />)}
      <Suspense fallback={null}>
        {eb(
          <Routes>
            <Route element={user ? <Outlet /> : <Navigate to={routes.HOME} />}>
              <Route path={routes.PROFILE} element={<ProfilePage />} />
              <Route path={routes.LOGOUT} element={<LogoutPage />} />
            </Route>
            <Route path={routes.REGISTER} element={<RegisterPage />} />
            <Route path={routes.TOPICS_ITEM} element={<ArticlePage />} />
            <Route path={routes.NEWS_ITEM} element={<ArticlePage />} />
            <Route path={routes.TOPICS} element={<TopicsPage />} />
            <Route path={routes.NEWS} element={<NewsPage />} />
            <Route path={routes.SEARCH} element={<SearchPage />} />
            <Route path={routes.HOME} element={<HomePage />} />
            <Route path={routes.CONTACTS} element={<ContactsPage />} />
            <Route path={routes.COMMITTEES} element={<CommitteesPage />} />
            <Route
              path={routes.SERVICE_AND_DOWNLOAD}
              element={<ServicesAndDownloadsPage />}
            />
            <Route path={routes.DEMO} element={<DemoPage />} />
            <Route path="*" element={<NotFoundPage />} />
          </Routes>,
          <OoupsPage />
        )}
      </Suspense>
      {eb([
        // <CookiesDialog />,
        <LoginDialog />,
        <PasswordDialog />,
        <ErrorDialog />,
      ])}
    </>
  );
}

export function App() {
  const { enableLinkTracking, trackPageView } = useMatomo();
  enableLinkTracking();

  const location = useLocation();

  useEffect(() => {
    if (!matomoDisabled()) {
      trackPageView();
    }
  }, [location, trackPageView]);

  const skip = !isAuth();
  const hideNotifications = location.pathname === routes.ARTICLE_PREVIEW;
  const status = [
    useGetApiV1AccountGetCurrentUserDataQuery(undefined, { skip }).isFetching,
    useGetApiV2UnionsQuery(undefined).isFetching,
    useGetApiV2AccountPreferencesQuery(undefined).isFetching,
  ];

  if (status.some((e) => e === true)) return null;

  return eb(
    <Theming>
      <Suspense fallback={null}>
        <Routes>
          <Route path={routes.CONFIRM_EMAIL} element={<ConfirmEmailPage />} />
          <Route
            path={routes.FORGOT_PASSWORD}
            element={<ForgotPasswordPage />}
          />
          <Route path={routes.PRIVACY} element={<PrivacyPage />} />
          <Route path={routes.IMPRINT} element={<ImprintPage />} />
          <Route path={routes.GDPR} element={<GdprPage />} />
          <Route path={routes.ARTICLE_PREVIEW} element={<ArticlePreview />} />
          <Route path="*" element={<RoutesWithHeader />} />
        </Routes>
        {!skip && !hideNotifications && eb(<Notifications />)}
        {eb(<ScrollToTop />)}
      </Suspense>
    </Theming>,
    <OoupsPage />
  ) as ReactElement;
}
