import { MenuProps as MuiMenuProps, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Menu from '@mui/material/Menu';
import { forwardRef, MouseEvent, useImperativeHandle, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ExampleNotification } from './ExampleNotification';

export const menuProps: Partial<MuiMenuProps> = {
  transformOrigin: { horizontal: 'left', vertical: 'bottom' },
  anchorOrigin: { horizontal: 'left', vertical: 'top' },
  PaperProps: {
    elevation: 0,
    sx: {
      overflow: 'visible',
      filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
      mt: -2,
      paddingInline: 2,
      paddingBlock: 1,
      '&:before': {
        content: '""',
        display: 'block',
        position: 'absolute',
        bottom: -10,
        left: 18,
        width: 10,
        height: 10,
        bgcolor: 'background.paper',
        transform: 'translateY(-50%) rotate(45deg)',
        zIndex: 0,
      },
    },
  },
  id: 'notifications-menu',
};

export type NotificationsMenuHandle = {
  handleOpen: (event: MouseEvent<HTMLElement>) => void;
};

type NotificationsMenuProps = {
  triggerSubscription: () => void;
  isSubscribedToNotifications: boolean;
};

export const NotificationsMenu = forwardRef<
  NotificationsMenuHandle,
  NotificationsMenuProps
>(function NotificationsMenu(
  { triggerSubscription, isSubscribedToNotifications },
  ref
) {
  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleOpen = (event: MouseEvent<HTMLElement>) =>
    setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  useImperativeHandle(ref, () => ({ handleOpen }), []);

  return (
    <Menu
      {...menuProps}
      {...{
        open,
        onClose: handleClose,
        onClick: handleClose,
        anchorEl,
        children: (
          <Box gap={1.5} display="flex" flexDirection="column">
            <Typography fontSize="18" fontWeight="bold" textAlign="center">
              {t('notifications.manage')}
            </Typography>
            <Divider />
            <ExampleNotification />
            <Button variant="contained" fullWidth onClick={triggerSubscription}>
              {isSubscribedToNotifications
                ? t('notifications.unsubscribe')
                : t('notifications.subscribe')}
            </Button>
          </Box>
        ),
      }}
    />
  );
});
