import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import {
  fetchSearchResults,
  initDataForSearchPage,
  SearchResultItem,
  setLiveSearchTerm,
  setSearchPageTerm,
  setShowLiveSearchResults,
} from './searchSlice';

export function useSearch() {
  const dispatch = useAppDispatch();

  const showLiveSearch = useAppSelector(
    (state) => state.search.showLiveSearchInput
  );
  const liveSearchTerm = useAppSelector((state) => state.search.liveSearchTerm);
  const liveSearchItems = useAppSelector(
    (state) => state.search.liveSearchItems || []
  ) as SearchResultItem[];
  const totalLiveSearchItems = useAppSelector(
    (state) => state.search.totalLiveSearchItems
  );
  const showLiveSearchResults = useAppSelector(
    (state) => state.search.showLiveSearchResults
  );
  const handleSearchTermChange = (term: string) => {
    dispatch(setLiveSearchTerm(term));
  };
  const handleShowLiveSearchResults = (show: boolean) =>
    dispatch(setShowLiveSearchResults(show));
  const handleSearchPageTermChange = (term: string) => {
    dispatch(setSearchPageTerm(term));
  };
  const handleInitDataForSearchPage = () => dispatch(initDataForSearchPage());
  const handleFetchingSearchResults = (searchTerm: string) => {
    dispatch(
      fetchSearchResults({
        searchTerm,
        page: 1,
      })
    );
  };

  return {
    showLiveSearch,
    liveSearchItems,
    liveSearchTerm,
    totalLiveSearchItems,
    showLiveSearchResults,
    handleSearchTermChange,
    handleSearchPageTermChange,
    handleShowLiveSearchResults,
    handleFetchingSearchResults,
    handleInitDataForSearchPage,
  };
}
