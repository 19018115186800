import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import FilledInput, { FilledInputProps } from '@mui/material/FilledInput';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import { forwardRef, ForwardRefExoticComponent, useState } from 'react';

interface AppPasswordFieldProps extends FilledInputProps {
  name: string;
  label: string;
}

export const AppPasswordField: ForwardRefExoticComponent<AppPasswordFieldProps> =
  forwardRef(({ name, label, ...rest }, ref) => {
    const [showPassword, setShowPassword] = useState(false);
    return (
      <FormControl variant="filled">
        <InputLabel htmlFor={name} required>
          {label}
        </InputLabel>
        <FilledInput
          ref={ref}
          required
          id={name}
          disableUnderline
          name={name}
          style={{ borderRadius: 8 }}
          type={showPassword ? 'text' : 'password'}
          autoComplete="current-password"
          {...rest}
        />
        <div
          style={{
            position: 'absolute',
            right: 0,
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            paddingRight: 8,
          }}
        >
          <IconButton
            aria-label="password-visibility"
            onClick={() => setShowPassword((prev) => !prev)}
          >
            {showPassword ? <VisibilityOff /> : <Visibility />}
          </IconButton>
        </div>
      </FormControl>
    );
  });
