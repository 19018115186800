import Link from '@mui/material/Link';
import styled from '@mui/material/styles/styled';
import SvgIcon from '@mui/material/SvgIcon';
import { ReactComponent as Facebook } from './facebook.svg';
import { ReactComponent as General } from './general.svg';
import { ReactComponent as Instagram } from './instagram.svg';
import { ReactComponent as LinkedIn } from './linkedin.svg';
import { ReactComponent as Twitter } from './twitter.svg';

const icons = {
  Facebook,
  Instagram,
  LinkedIn,
  Twitter,
  General,
};

export const FooterShell = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.footer.main,
  color: theme.palette.footer.main,
  marginTop: theme.spacing(8),
  paddingTop: theme.spacing(4),
  paddingBottom: theme.spacing(4),
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(4),
  alignItems: 'center',
}));

export const Social = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  gap: theme.spacing(3),
}));

export const SocialIcon = styled(({ name, ...rest }: { name: string }) => {
  const icon = name in icons ? icons[name as keyof typeof icons] : null;
  return !!icon ? <SvgIcon component={icon} {...rest} /> : null;
})(({ theme }) => ({
  height: '36px',
  width: '36px',
  fill: theme.palette.footer.contrastText,
}));

export const LegalLink = styled(Link)(({ theme }) => ({
  cursor: 'pointer',
  color: theme.palette.footer.contrastText,
}));
LegalLink.defaultProps = { variant: 'button', underline: 'hover' };
