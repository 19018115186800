import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';

export function LoginActions({
  handleShowLoginForm,
  handleRegister,
}: {
  handleShowLoginForm: () => void;
  handleRegister: () => void;
}) {
  const { t } = useTranslation();
  return (
    <>
      <Button
        variant="contained"
        color="secondary"
        style={{ flex: 1 }}
        onClick={handleShowLoginForm}
      >
        {t('login.login')}
      </Button>
      <Button
        variant="contained"
        color="primary"
        style={{ flex: 1 }}
        onClick={handleRegister}
      >
        {t('login.register')}
      </Button>
    </>
  );
}
