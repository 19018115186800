import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { AppPasswordField } from 'elements/AppPasswordField';
import { PasswordValid } from 'elements/PasswordValid';
import { passwordTests, usePassValidate } from 'hooks/usePassValidate';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

export function PasswordFormChangeOrReset({
  isLoading,
}: {
  isLoading?: boolean;
}) {
  const { t } = useTranslation(undefined, { keyPrefix: 'password' });

  const [searchParams] = useSearchParams();
  const email = searchParams.get('email');
  const token = searchParams.get('token');

  const { passState, isPassInvalid, onPassChange } = usePassValidate();

  return (
    <Box display="flex" gap={2} flexDirection="column">
      {email && token ? (
        <>
          <input type="hidden" name="email" value={email} />
          <input type="hidden" name="token" value={token} />
        </>
      ) : (
        <AppPasswordField
          name="oldPassword"
          label={t('current')}
          autoComplete="new-password"
          onChange={onPassChange}
        />
      )}
      <AppPasswordField
        name="password"
        label={t('new')}
        autoComplete="new-password"
        onChange={onPassChange}
      />
      <AppPasswordField
        name="confirmPassword"
        label={t('newConfirm')}
        autoComplete="new-password"
        onChange={onPassChange}
      />
      <Box display="flex" flexDirection="column">
        {passwordTests.map(({ key, test }) => (
          <PasswordValid
            checked={test.test(passState.password)}
            label={t(key)}
            key={key}
          />
        ))}
        <PasswordValid
          checked={
            !!passState.password.length &&
            passState.password === passState.confirmPassword
          }
          label={t('match')}
        />
      </Box>
      <Button
        variant="contained"
        color="secondary"
        fullWidth
        type="submit"
        disabled={
          isLoading ||
          isPassInvalid ||
          (!(email || token) && !passState.oldPassword.length)
        }
      >
        {t('action')}
      </Button>
    </Box>
  );
}
