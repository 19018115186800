import { useAppDispatch } from 'hooks/useRedux';
import { useCallback } from 'react';
import { handleModalsComment } from 'store/slices/modalsSlice';

export function useModalsCommentSet() {
  const dispatch = useAppDispatch();
  return useCallback(
    (state?: number) => dispatch(handleModalsComment(state)),
    [dispatch]
  );
}
