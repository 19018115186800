import type { PayloadAction } from '@reduxjs/toolkit';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

const DEFAULT_LIMIT = 5;

export type SearchResultItem = {
  id: number;
  typeId: number;
  type: 'RSS-Feed' | 'News' | 'Themen';
  title: string;
  teaser: string;
};

interface SearchState {
  liveSearchTerm: string;
  liveSearchItems: SearchResultItem[];
  totalLiveSearchItems: number;
  showLiveSearchInput: boolean;
  showLiveSearchResults: boolean;
  searchPageTerm: string;
  searchPageItems: SearchResultItem[];
  totalSearchPageItems: number;
  page: number;
  hasNextPage: number;
}

export type RequestBySearchTerm = {
  searchTerm: string;
  page: number;
  limit?: number;
};

export const fetchSearchResults = createAsyncThunk(
  'search/fetch',
  async ({ searchTerm, page, limit = DEFAULT_LIMIT }: RequestBySearchTerm) => {
    const searchParams = new URLSearchParams([
      ['FilterValue', searchTerm.toString()],
      ['PageIndex', page.toString()],
      ['PageSize', limit.toString()],
    ]);

    try {
      const response = await fetch('/api/v2/contents/search?' + searchParams);
      const data = await response.json();
      return data;
    } catch (err) {
      // TODO: handle errors
    }
  }
);

const initialState: SearchState = {
  liveSearchTerm: '',
  liveSearchItems: [],
  totalLiveSearchItems: 0,
  showLiveSearchInput: false,
  showLiveSearchResults: false,
  searchPageTerm: '',
  searchPageItems: [],
  totalSearchPageItems: 0,
  page: 1,
  hasNextPage: 0,
};

const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    setLiveSearchTerm(state, action: PayloadAction<string>) {
      state.liveSearchTerm = action.payload;
    },
    toggleShowLiveSearch(state) {
      state.showLiveSearchInput = !state.showLiveSearchInput;
      state.liveSearchTerm = '';
      state.showLiveSearchResults = false;
    },
    setShowLiveSearchResults(state, action: PayloadAction<boolean>) {
      state.showLiveSearchResults = action.payload;
    },
    setSearchPageTerm(state, action: PayloadAction<string>) {
      state.searchPageTerm = action.payload;
    },
    initDataForSearchPage(state) {
      state.searchPageTerm = state.liveSearchTerm;
      state.searchPageItems = state.liveSearchItems;
      state.totalSearchPageItems = state.totalLiveSearchItems;
    },
    fetchSearchResults(state) {
      fetchSearchResults({
        searchTerm: state.liveSearchTerm,
        page: 1,
      });
    },
    fetchMoreSearchResults(state) {
      fetchSearchResults({
        searchTerm: state.searchPageTerm,
        page: state.page++,
      });
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSearchResults.fulfilled, (state, action) => {
      if (action.payload === undefined) return;
      const items = action.payload.data?.items;
      if (!items) return;
      const { page } = action.meta.arg as Required<RequestBySearchTerm>;
      if (page > 1) {
        state.searchPageItems = [...state.searchPageItems, ...items];
      } else {
        state.liveSearchItems = items;
      }
      const totalItems = action.payload.data?.totalCount;
      const hasNextPage = action.payload.data?.hasNextPage;
      state.page = page;
      state.totalLiveSearchItems = totalItems!;
      state.hasNextPage = hasNextPage!;
    });
  },
});

export const {
  setLiveSearchTerm,
  toggleShowLiveSearch,
  setShowLiveSearchResults,
  setSearchPageTerm,
  initDataForSearchPage,
} = searchSlice.actions;

export default searchSlice.reducer;
