import { BackButton } from 'elements/BackButton';
import { Banner } from 'elements/Banner';
import { errorBoundary } from 'elements/ErrorBoundary';
import { Main } from 'elements/Main';
import { useBanner } from 'hooks/useBanner';
import { CommentDialog } from 'modules/CommentDialog';
import { News } from 'modules/News';

export function NewsPage() {
  const banners = useBanner(1);

  return (
    <Main>
      <BackButton />
      {errorBoundary([
        <News showMore />,
        <Banner banner={banners[0]} />,
        <CommentDialog />,
      ])}
    </Main>
  );
}
