export const routes = {
  HOME: '/',
  REGISTER: '/register',
  PROFILE: '/profile',
  PROFILE_UNION: '/profile#union',
  DEMO: '/demo',
  NEWS: '/news',
  NEWS_ITEM: '/news/:idOrUri',
  SEARCH: '/search',
  TOPICS: '/themen',
  TOPICS_ITEM: '/themen/:idOrUri',
  CONTACTS: '/contacts',
  COMMITTEES: '/committees',
  SERVICE_AND_DOWNLOAD: '/services-and-downloads',
  IMPRINT: '/impressum',
  PRIVACY: '/datenschutz',
  GDPR: '/nutzungsbedingungen',
  LOGOUT: '/logout',
  CONFIRM_EMAIL: '/confirmemail',
  FORGOT_PASSWORD: '/forgot-password',
  ARTICLE_PREVIEW: '/article-preview',
};
