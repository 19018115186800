import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

export type Message = { show: boolean; heading?: string; content?: string };

interface ModalsState {
  login: boolean;
  joinNow: boolean;
  comment?: number;
  error: boolean;
  cookies: boolean;
  password?: number;
  message: Message;
}

const initialState = {
  login: false,
  joinNow: false,
  welcome: false,
  comment: undefined,
  error: false,
  cookies: false, // Cookies.get('accept-necessary') !== 't',
  password: undefined,
  message: { show: false },
} as ModalsState;

const modalsSlice = createSlice({
  name: 'modals',
  initialState,
  reducers: {
    handleModalsLogin(state, action: PayloadAction<boolean>) {
      state.login = action.payload;
    },
    handleModalsJoinNow(state, action: PayloadAction<boolean>) {
      state.joinNow = action.payload;
    },
    handleModalsComment(state, action: PayloadAction<number | undefined>) {
      state.comment = action.payload;
    },
    handleModalsError(state, action: PayloadAction<boolean>) {
      state.error = action.payload;
    },
    handleModalsCookies(state, action: PayloadAction<boolean>) {
      state.cookies = action.payload;
    },
    handleModalsPassword(state, action: PayloadAction<number | undefined>) {
      state.password = action.payload;
    },
    handleModalsMessage(state, action: PayloadAction<Message | undefined>) {
      if (action.payload === undefined)
        return void (state.message.show = false);
      state.message = { ...state.message, ...action.payload };
    },
  },
});

export const {
  handleModalsLogin,
  handleModalsJoinNow,
  handleModalsComment,
  handleModalsError,
  handleModalsCookies,
  handleModalsPassword,
  handleModalsMessage,
} = modalsSlice.actions;

export default modalsSlice.reducer;
