import { ChangeEvent, useState } from 'react';

export const passwordTests = [
  { test: /(?=.{8,})/, key: 'eightMin' },
  { test: /(?=.*[a-z])/, key: 'oneLower' },
  { test: /(?=.*[A-Z])/, key: 'oneUpper' },
  { test: /(?=.*[0-9])/, key: 'oneNumber' },
  { test: /(?=.*[!@#$%^&*])/, key: 'oneSpecial' },
];

type PasswordType = 'oldPassword' | 'password' | 'confirmPassword';

export function usePassValidate() {
  const [passState, setPassState] = useState<Record<PasswordType, string>>({
    oldPassword: '',
    password: '',
    confirmPassword: '',
  });
  const onPassChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => setPassState((prev) => ({ ...prev, [e.target.name]: e.target.value }));

  const isPassInvalid = passwordTests.some(
    ({ test }) =>
      !test.test(passState.password) ||
      passState.password !== passState.confirmPassword
  );

  return { passState, isPassInvalid, onPassChange };
}
