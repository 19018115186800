import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import styled from '@mui/material/styles/styled';
import { useNavigateTop } from 'hooks/useNavigateTop';
import { useTranslation } from 'react-i18next';

const BackButtonShell = styled(IconButton)(({ theme }) => ({
  alignSelf: 'start',
}));

interface BackButtonProps extends IconButtonProps {
  to?: string | number;
}

export function BackButton({ to, ...rest }: BackButtonProps) {
  const navigate = useNavigateTop();
  const { t } = useTranslation();
  return (
    <BackButtonShell
      color="primary"
      aria-label={t('common.back')}
      onClick={() => (to ? navigate(to as number) : navigate('/'))} // navigate is badly typed
      {...rest}
    >
      <ArrowBackIcon fontSize="large" />
    </BackButtonShell>
  );
}
