import { handleModalsError } from 'store/slices/modalsSlice';
import { api } from './api';
import { Likes } from './types';

export const apiFeedback = api
  .injectEndpoints({
    endpoints: (build) => ({
      getApiV2Reactions: build.query<Likes, undefined>({
        query: () => ({ url: `/api/v2/reactions` }),
        transformResponse(baseQueryReturnValue) {
          return (baseQueryReturnValue as any).data || {};
        },
      }),

      putApiV2Reactions: build.mutation<
        undefined | null,
        { id: number; value: boolean | null }
      >({
        query: ({ id, value }) => ({
          url: `/api/v2/reactions/${id}`,
          method: 'PUT',
          data: { value },
        }),
        async onQueryStarted({ id, value }, { dispatch, queryFulfilled }) {
          const patchResult = dispatch(
            apiFeedback.util.updateQueryData(
              'getApiV2Reactions',
              undefined,
              (draft) => {
                if (value === null) return void delete draft[id];
                draft[id] = value;
              }
            )
          );
          try {
            const result = await queryFulfilled;
            if ((result.data as any)?.succeded === false) {
              patchResult.undo();
              dispatch(handleModalsError(true));
            }
          } catch {
            patchResult.undo();
            dispatch(handleModalsError(true));
          }
        },
      }),

      postApiV2Comments: build.mutation<
        undefined | null,
        { id: number; value: string }
      >({
        query: ({ id, value }) => ({
          url: `/api/v2/comments/${id}`,
          method: 'POST',
          data: { value },
        }),
      }),
    }),
  })
  .enhanceEndpoints({
    endpoints: {
      getApiV2Reactions: {
        providesTags: ['Auth'],
      },
    },
  });

export const {
  useGetApiV2ReactionsQuery,
  usePutApiV2ReactionsMutation,
  usePostApiV2CommentsMutation,
} = apiFeedback;
