import Divider from '@mui/material/Divider';
import { Feedback, FeedbackProps } from 'elements/Feedback';
import { Photo, PhotoProps } from 'elements/Photo';
import { Tags, TagsProps } from 'elements/Tags';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
  SummaryReadMore,
  SummaryShell,
  SummaryText,
  SummaryTitle,
} from './SummaryElements';

interface SummaryProps extends TagsProps, FeedbackProps {
  handleReadMore: () => void;
  title?: string;
  summary?: string;
  image?: PhotoProps;
}

export const Summary: FC<SummaryProps> = ({
  tags,
  image,
  isLiked,
  handleComment,
  handleLike,
  handleReadMore,
  title,
  summary,
}) => {
  const { t } = useTranslation();
  return (
    <SummaryShell>
      <Divider variant="fullWidth" />

      <Tags
        tags={tags}
        style={{
          marginBlock: '1.5rem',
        }}
      />

      <Photo {...(image || {})} />

      <SummaryTitle>{title}</SummaryTitle>

      <SummaryText>{summary}</SummaryText>

      <Feedback
        {...{
          isLiked,
          handleComment,
          handleLike,
        }}
      />

      <SummaryReadMore
        onClick={handleReadMore}
        children={t('common.readMore')}
      />
    </SummaryShell>
  );
};
