import styled from '@mui/material/styles/styled';
import Typography from '@mui/material/Typography';

import { CSSProperties, FC } from 'react';

const TagsShell = styled(Typography)(({ theme, style }) => ({
  '&::after': {
    content: '"\u200b"',
  },
  ...style,
}));
TagsShell.defaultProps = { variant: 'subtitle2' };

export interface TagsProps {
  tags?: {
    id: number;
    name?: string;
  }[];
  style?: CSSProperties;
}

export const Tags: FC<TagsProps> = ({ tags, style }) => {
  return (
    <TagsShell style={style}>{tags?.map((e) => e.name).join(' ')}</TagsShell>
  );
};
