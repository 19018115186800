import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import ThemeProvider from '@mui/material/styles/ThemeProvider';
import Typography from '@mui/material/Typography';
import { Main } from 'elements/Main';
import { useTranslation } from 'react-i18next';
import { routes } from 'routes';
import logo from './logo.png';
import { ooupsTheme } from './ooupsTheme';

// WARNING: This component has to be stand-alone (no app theme, no data, etc)!!!
export function OoupsPage() {
  const { t } = useTranslation();
  return (
    <ThemeProvider theme={ooupsTheme}>
      <CssBaseline />
      <Main
        style={{
          height: '100vh',
          textAlign: 'center',
          gap: 24,
          maxWidth: 666,
          margin: 'auto',
          padding: 12,
        }}
      >
        <img
          src={logo}
          alt="zukunftsnetzwerk digital"
          width={256}
          height={46}
        />
        <Divider />
        <Typography variant="h1">{t('ooups.title')}</Typography>
        <Typography>
          {t('ooups.content')}
          <a
            href="mailto:digital-bta@dbb.de"
            target="_blank"
            rel="noreferrer"
            style={{ whiteSpace: 'nowrap' }}
          >
            digital-bta@dbb.de
          </a>
          .
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            window.location.replace(routes.HOME);
          }}
        >
          {t('ooups.button')}
        </Button>
      </Main>
    </ThemeProvider>
  );
}
