import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { IconButton, SvgIcon } from '@mui/material';
import styled from '@mui/material/styles/styled';
import MuiToolbar from '@mui/material/Toolbar';

export const Toolbar = styled(MuiToolbar)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  maxWidth: `min(calc(100vw - ${theme.spacing(6)}), ${
    theme.breakpoints.values.lg
  }px)`,
  paddingInline: '0!important',
}));

export const ToolbarHead = styled('div')(({ theme }) => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'end',
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(1),
  lineHeight: 0,
}));

export const Logo = styled('img')(({ theme }) => ({
  height: theme.spacing(5),
  maxWidth: theme.spacing(30),
  minWidth: theme.spacing(5),
  cursor: 'pointer',
}));
Logo.defaultProps = {
  tabIndex: 0,
  role: 'button',
  height: 40,
};

export const ToolbarHeadButtonGroup = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1),
}));

export const HeaderContainer = styled('div')(({ theme }) => ({
  position: 'fixed',
  top: 0,
  zIndex: theme.zIndex.appBar,
  width: '100%',
}));

export const HeaderPlaceholder = 'div';

export const ButtonSearch = styled(IconButton)(({ theme }) => ({
  lineHeight: 0,
  padding: 0,
}));
ButtonSearch.defaultProps = {
  children: (
    <SvgIcon>
      <SearchOutlinedIcon />
    </SvgIcon>
  ),
  color: 'primary',
};
