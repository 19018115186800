import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import NotificationsOffIcon from '@mui/icons-material/NotificationsOff';
import Fab from '@mui/material/Fab';
import Tooltip from '@mui/material/Tooltip';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import {
  NotificationsMenu,
  NotificationsMenuHandle,
} from './NotificationsMenu';
import { useNotifications } from './useNotifications';

export function Notifications() {
  const { t } = useTranslation();
  const ref = useRef<NotificationsMenuHandle>(null);
  const {
    showFeedbackOnNotificationSubscriptionChange,
    isSubscribedToNotifications,
    triggerSubscription,
  } = useNotifications();

  if (isSubscribedToNotifications === null) return null;

  return (
    <>
      <Tooltip
        // hotfix: open does not trigger a tooltip to open
        key={+!!showFeedbackOnNotificationSubscriptionChange}
        title={
          showFeedbackOnNotificationSubscriptionChange
            ? isSubscribedToNotifications
              ? t('notifications.tip.subscribed')
              : t('notifications.tip.unsubscribed')
            : isSubscribedToNotifications
            ? t('notifications.tip.isSubscribed')
            : t('notifications.tip.canSubscribe')
        }
        arrow
        placement="right"
        open={showFeedbackOnNotificationSubscriptionChange}
      >
        <Fab
          size="medium"
          color="primary"
          aria-label={t('notifications.subscribe')}
          sx={(theme) => ({
            boxShadow: '0px 2px 8px 0px rgb(0 0 0 / 0.3)',
            position: 'fixed',
            bottom: 0,
            left: 0,
            margin: 3,
          })}
          onClick={(e) => ref.current?.handleOpen(e)}
          aria-haspopup="true"
          disabled={showFeedbackOnNotificationSubscriptionChange}
        >
          {isSubscribedToNotifications ? (
            <NotificationsActiveIcon />
          ) : (
            <NotificationsOffIcon />
          )}
        </Fab>
      </Tooltip>
      <NotificationsMenu
        {...{ ref, isSubscribedToNotifications, triggerSubscription }}
      />
    </>
  );
}
