import { Contact } from 'elements/Contact';
import { MapOsm } from 'elements/MapOsm';
import { SectionHeader } from 'elements/SectionHeader';
import { useBands } from 'hooks/useBands';
import { useUnions } from 'hooks/useUnions';
import { useUser } from 'hooks/useUser';
import { useTranslation } from 'react-i18next';
import { selectUserInformal } from 'selectors';
import {
  ContactsDetails,
  ContactsShell,
  MapMessageShell,
} from './ContactsElements';
import { ContactsMessage } from './ContactsMessage';

const getName = (...args: (string | undefined)[]) =>
  args.filter(Boolean).join(' ');

export function Contacts() {
  const { t } = useTranslation(undefined, { keyPrefix: 'contacts' });
  const [user] = useUser();
  const informal = selectUserInformal(user);
  const [unions] = useUnions();
  const [hiddenUnionIds, setHiddenUnionids] = useBands('contacts');
  const union = unions.find((u) => !hiddenUnionIds.includes(u.id))!;

  const { lat, lon, zoom } = union?.contact?.union?.coordinates || {};
  const { title, firstName, lastName, phone, email, photo } =
    union?.contact?.person || {};
  const unionContact = union?.contact?.union || {};

  const name = getName(title, firstName, lastName);

  return (
    <ContactsShell>
      <SectionHeader
        titleKey="contacts.title"
        accent={
          informal ? 'approach.forYou.informal' : 'approach.forYou.formal'
        }
        selected={[union.id]}
        unions={unions}
        handleClick={(id) => () =>
          setHiddenUnionids(unions.filter((u) => u.id !== id).map((e) => e.id))}
      />
      <ContactsDetails>
        {!!name.length && (
          <Contact
            header={t('yourContact')}
            {...{ name, phone, email, photo }}
          />
        )}
        <Contact
          header={union.nameFull}
          {...unionContact}
          content={unionContact.address.split(',').map((e: string) => (
            <span key={e}>{e.trim()}</span>
          ))}
          hidePhoto
        />
      </ContactsDetails>
      <MapMessageShell isLoggedIn={!!user}>
        <MapOsm
          lat={lat / 1_000_000}
          lon={lon / 1_000_000}
          zoom={zoom / 1_000}
        />
        {!!user && <ContactsMessage unionId={union.id} informal={informal} />}
      </MapMessageShell>
    </ContactsShell>
  );
}
