import Cookies, { CookieAttributes } from 'js-cookie';

// Expects top level domain (.com, .de, not .co.uk)
export const domain = window.location.hostname.split('.').slice(-2).join('.');

export const cookieOptions: CookieAttributes = {
  expires: 42,
  domain,
  secure: true,
  sameSite: 'Lax',
};

export function extendCookies() {
  if (Cookies.get('accept-necessary') === 't')
    Cookies.set('accept-necessary', 't', cookieOptions);
  if (Cookies.get('accept-statistical') === 't')
    Cookies.set('accept-statistical', 't', cookieOptions);
}
