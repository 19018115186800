import Button from '@mui/material/Button';
import styled from '@mui/material/styles/styled';

export const SummaryShell = styled('summary')(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  wordBreak: 'break-word',
}));

export const SummaryTitle = styled('h1')(({ theme }) => ({
  fontSize: 22,
  wordBreak: 'break-word',
}));

export const SummaryText = styled('p')(({ theme }) => ({
  fontSize: 18,
  flex: 1,
  wordBreak: 'break-word',
}));

export const SummaryReadMore = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
  width: 'max-content',
}));
SummaryReadMore.defaultProps = { variant: 'contained' };
