import styled from '@mui/material/styles/styled';
import SvgIcon from '@mui/material/SvgIcon';
import { useApproach } from 'hooks/useApproach';
import { useTranslation } from 'react-i18next';
import { ReactComponent as AccountChecked } from './icons/account-checked.svg';
import { Accent } from './MenusElements';

export const Title = styled('div')(({ theme }) => ({
  fontFamily: 'Poppins',
  fontSize: '22px',
  fontWeight: 'bold',
  paddingBottom: theme.spacing(1),
}));

export const AccountMenuHeader = styled(
  ({ mobile, ...rest }: { mobile?: boolean }) => {
    const { t } = useTranslation(undefined, { keyPrefix: 'menu' });
    const approach = useApproach();

    return (
      <div {...rest}>
        <SvgIcon component={AccountChecked} sx={{ display: 'block', mb: 1 }} />
        {t('hello')} <Accent>{approach}</Accent>
        <Title>{t('myAccount')}</Title>
      </div>
    );
  }
)(({ theme, mobile }) => ({
  paddingTop: theme.spacing(mobile ? 2 : 1),
  paddingInline: theme.spacing(2),
}));
