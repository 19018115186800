import { useTranslation } from 'react-i18next';
import { generatePath, Link } from 'react-router-dom';
import { routes } from 'routes';
import {
  AllResultsLink,
  NoResults,
  ResultsList,
  ResultsListItem,
  ResultsShell,
} from './SearchElements';
import { SearchResultItem } from './searchSlice';

export interface SearchResultsProps {
  results: SearchResultItem[];
  totalLiveSearchItems: number;
  handleSearchTermChange: (searchTerm: string) => void;
  handleShowLiveSearchResults: (showLiveSearchResults: boolean) => void;
  handleInitDataForSearchPage: () => void;
}

const SearchResults = ({
  results,
  totalLiveSearchItems,
  handleSearchTermChange,
  handleShowLiveSearchResults,
  handleInitDataForSearchPage,
}: SearchResultsProps) => {
  const { t } = useTranslation();

  const generateRoute = (item: SearchResultItem) => {
    if (item.typeId === 1)
      return generatePath(routes.TOPICS_ITEM, { idOrUri: item.id });
    return generatePath(routes.NEWS_ITEM, { idOrUri: item.id });
  };

  const getItemType = (itemType: SearchResultItem['type']) => {
    if (itemType === 'RSS-Feed') return 'News';
    return itemType;
  };

  const onLinkClick = () => {
    handleSearchTermChange('');
    handleShowLiveSearchResults(false);
  };

  const onSearchLinkClick = () => {
    handleInitDataForSearchPage();
    handleShowLiveSearchResults(false);
  };

  return (
    <>
      {results && (
        <ResultsShell>
          {results.length > 0 && (
            <>
              <ResultsList>
                {results.map((item: SearchResultItem) => {
                  return (
                    <ResultsListItem key={item.id}>
                      <Link to={generateRoute(item)} onClick={onLinkClick}>
                        {getItemType(item.type)}: {item.title}
                      </Link>
                    </ResultsListItem>
                  );
                })}
              </ResultsList>
              <AllResultsLink>
                <Link to={routes.SEARCH} onClick={onSearchLinkClick}>
                  {t('search.showAll')} ({totalLiveSearchItems})
                </Link>
              </AllResultsLink>
            </>
          )}
          {results.length === 0 && <NoResults>No results</NoResults>}
        </ResultsShell>
      )}
    </>
  );
};

export default SearchResults;
