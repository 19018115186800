import styled from '@mui/material/styles/styled';
import { getApproachT } from 'helpers/getApproachT';
import { useApproach } from 'hooks/useApproach';
import { useUnion } from 'hooks/useUnion';
import { useUser } from 'hooks/useUser';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router-dom';
import { routes } from 'routes';
import { selectUserInformal } from 'selectors';
import { useGetApiV1SliderForCurrentUserQuery } from 'store/api';
import { HeroElement } from './HeroElement';
import BgFirst from './slide-first.jpg';
import BgLast from './slide-last.jpg';

const Strong = styled('span')(({ theme }) => ({
  color: theme.palette.accent,
}));

export const HeroUser: FC = () => {
  const { t } = useTranslation(undefined, { keyPrefix: 'hero' });

  const navigate = useNavigate();
  const { data, isLoading } = useGetApiV1SliderForCurrentUserQuery(undefined);
  const approach = useApproach();
  const [user] = useUser();
  const union = useUnion();
  const informal = selectUserInformal(user);
  const at = getApproachT(t)(informal);

  const onClickSlide = (idOrUri: number | undefined) => {
    if (!idOrUri) return;
    navigate(
      generatePath(routes.NEWS_ITEM, {
        idOrUri,
      })
    );
  };

  const unionName = union?.nameShort || '';

  const images = [
    {
      src: BgFirst,
      title: (
        <>
          {t('hello')} <Strong>{approach}</Strong>
          {', '}
          {at('welcome')} {unionName} {t('network')}
          {'!'}
        </>
      ),
      text: `${t('sliderDescription')} ${unionName}.`,
      button: t('viewAllNews'),
      onClick: () => {
        navigate(generatePath(routes.NEWS));
      },
    },
    {
      src: data?.snippet2?.previewImageUrl,
      title: data?.snippet2?.title,
      text: data?.snippet2?.teaser,
      button: t('readMore'),
      onClick: () => onClickSlide(data?.snippet2?.id),
    },
    {
      src: data?.snippet3?.previewImageUrl,
      title: data?.snippet3?.title,
      text: data?.snippet3?.teaser,
      button: t('readMore'),
      onClick: () => onClickSlide(data?.snippet3?.id),
    },
    {
      src: data?.snippet4?.previewImageUrl,
      title: data?.snippet4?.title,
      text: data?.snippet4?.teaser,
      button: t('readMore'),
      onClick: () => onClickSlide(data?.snippet4?.id),
    },
    {
      src: BgLast,
      title: t('fifthSlideTitle'),
      text: t('fifthSlideText'),
      button: t('fifthSlideTitle'),
      onClick: () =>
        window.open('https://www.dbb-vorteilswelt.de/aktuelles', '_blank'),
    },
  ];

  if (!!data && !data.snippet4) images.splice(3, 1);

  return isLoading ? null : <HeroElement images={images} />;
};
