import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';

export const TopicsShell = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: theme.spacing(4),
  width: '100%',
}));

export const TopicsContent = styled(Box)(({ theme }) => ({
  display: 'grid',
  width: '100%',
  gridTemplateColumns: '1fr 1fr',
  gap: theme.spacing(4),
  columnGap: theme.spacing(6),
  [theme.breakpoints.down('md')]: {
    display: 'flex',
    flexDirection: 'column',
  },
}));

export const TopicsReadMore = styled(Button)(({ theme, hidden }) => ({
  marginTop: theme.spacing(2),
  visibility: !hidden ? 'visible' : 'hidden',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
}));
TopicsReadMore.defaultProps = { variant: 'contained' };
