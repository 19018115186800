import { CreateUserSchema } from 'data/schemas/createUser';
import type { UpdateUserSchema } from 'data/schemas/updateUser';
import { api } from './api';
import { ChangePassword, Credentials, ResetPassword } from './types';

export const apiAuth = api
  .injectEndpoints({
    endpoints: (build) => ({
      postApiV2AuthLogin: build.mutation<unknown, { body: Credentials }>({
        query: ({ body }) => ({
          url: `/api/v2/auth/login`,
          method: 'POST',
          data: body,
        }),
      }),

      postApiV2AuthLogout: build.mutation<unknown, void>({
        query: () => ({ url: `/api/v2/auth/logout`, method: 'POST' }),
      }),

      postApiV2AccountRegister: build.mutation<
        unknown,
        { body: CreateUserSchema }
      >({
        query: ({ body }) => ({
          url: `/api/v1/account/register`,
          method: 'POST',
          data: body,
        }),
      }),

      getApiV1AccountGetCurrentUserData: build.query({
        query: () => ({ url: `/api/v1/account/getcurrentuserdata` }),
        transformResponse(baseQueryReturnValue, meta, arg) {
          return (baseQueryReturnValue as any).data;
        },
      }),

      putApiV1AccountUpdateUserProfile: build.mutation<
        unknown,
        { body: UpdateUserSchema }
      >({
        query: (queryArg) => ({
          url: `/api/v1/Account/updateuserprofile`,
          method: 'PUT',
          data: queryArg.body,
        }),
      }),

      putApiV1AccountChangePassword: build.mutation<
        unknown,
        { body: ChangePassword }
      >({
        query: (queryArg) => ({
          url: `/api/v1/Account/changepassword`,
          method: 'PUT',
          data: queryArg.body,
        }),
      }),

      postApiV1AccountResetPassword: build.mutation<
        unknown,
        { body: ResetPassword }
      >({
        query: (queryArg) => ({
          url: `/api/v1/account/resetpassword`,
          method: 'POST',
          data: queryArg.body,
        }),
      }),

      postApiV1AccountForgotPassword: build.mutation<
        unknown,
        { body: Pick<ResetPassword, 'email'> }
      >({
        query: (queryArg) => ({
          url: `/api/v1/account/forgotpassword`,
          method: 'POST',
          data: queryArg.body,
        }),
      }),

      postApiV1AccountRequestDeletion: build.mutation<
        undefined,
        { password: string }
      >({
        query: ({ password }) => ({
          url: `/api/v1/Account/RequestDeletion`,
          method: 'POST',
          data: { password },
        }),
      }),

      postApiV1AccountConfirmEmail: build.query<
        undefined,
        { body: { email: string | null; token: string | null } }
      >({
        query: ({ body: data }) => ({
          url: `/api/v1/account/confirmemail`,
          method: 'POST',
          data,
        }),
      }),

      getApiV1UnionsByFederalStateId: build.query({
        query: (queryArg) => ({
          url: `/api/v1/union/getunionsbyfederalstateid/${queryArg.id}`,
        }),
        transformResponse(baseQueryReturnValue) {
          return (baseQueryReturnValue as any).data;
        },
      }),
    }),
  })
  .enhanceEndpoints({
    endpoints: {
      getApiV1AccountGetCurrentUserData: {
        providesTags: ['Auth'],
      },
      putApiV1AccountUpdateUserProfile: {
        invalidatesTags: (result) => (result !== undefined ? ['Auth'] : []),
      },
      postApiV2AuthLogin: {
        invalidatesTags: (result) => (result !== undefined ? ['Auth'] : []),
      },
      postApiV2AuthLogout: {
        invalidatesTags: (result) => (result !== undefined ? ['Auth'] : []),
      },
    },
  });

export const {
  endpoints: {
    getApiV1AccountGetCurrentUserData: {
      useQueryState: useGetApiV1AccountGetCurrentUserDataQueryState,
    },
  },
  usePostApiV2AuthLoginMutation,
  usePostApiV2AuthLogoutMutation,
  usePostApiV2AccountRegisterMutation,
  usePutApiV1AccountChangePasswordMutation,
  usePostApiV1AccountResetPasswordMutation,
  useGetApiV1AccountGetCurrentUserDataQuery,
  usePostApiV1AccountRequestDeletionMutation,
  usePostApiV1AccountForgotPasswordMutation,
  usePutApiV1AccountUpdateUserProfileMutation,
  usePostApiV1AccountConfirmEmailQuery,
  useGetApiV1UnionsByFederalStateIdQuery,
} = apiAuth;
