import { useUnions } from 'hooks/useUnions';
import { useCallback, useEffect, useLayoutEffect, useState } from 'react';

const w = window as any;

export function useNotifications() {
  const [unions] = useUnions();

  // null is the before the first sync
  const [isSubscribedToNotifications, setIsSubscribedToNotifications] =
    useState<boolean | null>(null);

  // undefined is regular/hover tooltip state
  const [
    showFeedbackOnNotificationSubscriptionChange,
    setShowFeedbackOnNotificationSubscriptionChange,
  ] = useState<true | undefined>(undefined);

  const syncIsSubscribedToNotifications = useCallback(async () => {
    const wp = await w.WonderPush.ready();
    const nIsSubscribedToNotifications: boolean =
      await wp.isSubscribedToNotifications();

    if (isSubscribedToNotifications !== nIsSubscribedToNotifications) {
      if (
        isSubscribedToNotifications !== null &&
        nIsSubscribedToNotifications !== isSubscribedToNotifications
      )
        // show feedback only if there is synced subscribed state change
        setShowFeedbackOnNotificationSubscriptionChange(true);

      setIsSubscribedToNotifications(nIsSubscribedToNotifications);
    }
  }, [isSubscribedToNotifications]);

  const triggerSubscription = async () => {
    const wp = await w.WonderPush.ready();
    if (isSubscribedToNotifications) {
      await wp.unsubscribeFromNotifications();
    } else {
      await wp.subscribeToNotifications();
      unions.forEach(async ({ id }) => {
        await wp.addTag(`union-${id}`);
      });
    }
    syncIsSubscribedToNotifications();
  };

  // Sync subscription status on mount
  useLayoutEffect(() => {
    w.WonderPush ||= [];
    w.WonderPush.push(syncIsSubscribedToNotifications);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Hide feedback after 3s
  useEffect(
    () =>
      void (
        showFeedbackOnNotificationSubscriptionChange &&
        setTimeout(
          () => setShowFeedbackOnNotificationSubscriptionChange(undefined),
          3000
        )
      ),
    [showFeedbackOnNotificationSubscriptionChange]
  );

  return {
    showFeedbackOnNotificationSubscriptionChange,
    isSubscribedToNotifications,
    triggerSubscription,
  };
}
