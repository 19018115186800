import { useBands } from 'hooks/useBands';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { useEffect, useMemo } from 'react';
import { Union } from 'store/api';
import { fetchTopicsPage, nextPage } from './topicsSlice';

const h = (ids: number[]) => ids.join(',');
const comparer = (a: number | string, b: number | string) =>
  a.toString().localeCompare(b.toString());

export function useTopics(unions: Union[]) {
  const [hiddenUnionIds, setHiddenUnionIds] = useBands('topics');
  const unionIds = useMemo(
    () =>
      unions
        .filter((e) => !hiddenUnionIds.includes(e.id) && e.isVisible.topics)
        .map((e) => e.id as number)
        .sort(comparer),
    [hiddenUnionIds, unions]
  );
  const hUnionIds = h(unionIds);
  const items = useAppSelector((state) => state.topics.items[hUnionIds] || []);
  const page = useAppSelector((state) => state.topics.page[hUnionIds] || 1);
  const hasMorePages = useAppSelector(
    (state) => state.topics.page[hUnionIds] < state.topics.total[hUnionIds]
  );

  const dispatch = useAppDispatch();
  const handleLoadNextPage = () => dispatch(nextPage({ unionIds }));
  const handleToggleUnion = (unionId: number) => () => {
    if (!hiddenUnionIds.includes(unionId) && unionIds.length > 1)
      return setHiddenUnionIds([...hiddenUnionIds, unionId]);
    setHiddenUnionIds(hiddenUnionIds.filter((id) => id !== unionId));
  };

  useEffect(() => {
    dispatch(
      fetchTopicsPage({
        hUnionIds,
        page,
      })
    );
  }, [hUnionIds, page, dispatch]);

  return {
    items,
    selected: unionIds,
    handleLoadNextPage,
    handleToggleUnion,
    hasMorePages,
  };
}
