import { useAppDispatch } from 'hooks/useRedux';
import { useCallback } from 'react';
import { handleModalsJoinNow } from 'store/slices/modalsSlice';

export function useModalsJoinNowSet() {
  const dispatch = useAppDispatch();
  return useCallback(
    (state: boolean) => dispatch(handleModalsJoinNow(state)),
    [dispatch]
  );
}
