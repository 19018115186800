import { useAppDispatch } from 'hooks/useRedux';
import { useCallback } from 'react';
import { handleModalsPassword } from 'store/slices/modalsSlice';

export enum ModalsPasswordEnum {
  'closed',
  'init',
  'reset',
}

export function useModalsPasswordSet() {
  const dispatch = useAppDispatch();
  return useCallback(
    (state?: number) => dispatch(handleModalsPassword(state)),
    [dispatch]
  );
}
