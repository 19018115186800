import { errorBoundary } from 'elements/ErrorBoundary';
import { OoupsPage } from 'pages/OoupsPage';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import { i18n } from 'services/i18n';
import { MatomoAnalyticsProvider } from 'services/matomo';
import { App } from './App';
import { StoreProvider } from './store';
i18n();
// extendCookies();

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  errorBoundary(
    <StoreProvider>
      <MatomoAnalyticsProvider>
        <Router>
          <App />
        </Router>
      </MatomoAnalyticsProvider>
    </StoreProvider>,
    <OoupsPage />
  )
);
