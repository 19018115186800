import { AppDialog } from 'elements/AppDialog';
import { useModals } from 'hooks/useModals';
import { useModalsPasswordSet } from 'hooks/useModalsPasswordSet';
import { useUser } from 'hooks/useUser';
import { FormEventHandler, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { selectUserInformal } from 'selectors';
import {
  ChangePassword,
  ResetPassword,
  usePostApiV1AccountForgotPasswordMutation,
  usePostApiV1AccountResetPasswordMutation,
  usePutApiV1AccountChangePasswordMutation,
} from 'store/api';
import { getApproachT } from '../../helpers/getApproachT';
import { PasswordForm } from './PasswordForm';
import { PasswordTitle } from './PasswordTitle';

type Message = {
  show?: boolean;
  handleClose?: () => void;
  title?: string;
  content?: string;
};

// TODO: Cleanup, split to reset password request and change/reset password, extract logic into hook
export function PasswordDialog({ onClose }: { onClose?: () => void }) {
  const { t } = useTranslation(undefined, { keyPrefix: 'password' });

  const modals = useModals();

  const [user] = useUser();
  const informal = selectUserInformal(user);
  const at = getApproachT(t)(informal);

  const [phase, setPhase] = useState<number>();
  useEffect(() => {
    if (modals.password) setPhase(modals.password);
  }, [modals.password]);

  const setModalsPassword = useModalsPasswordSet();
  const handleClose = () => {
    setModalsPassword(undefined);
    onClose?.();
  };

  const [message, setMessage] = useState<Message>({});
  const handleShowMessage = (m: Message = {}) => {
    setMessage({ ...m, show: true });
  };
  const handleHideMessage = () => {
    message.handleClose?.();
    setMessage(({ title, content }) => ({
      title,
      content,
      show: false,
    }));
  };

  const [changePassword, { isLoading: isLoadingChangePassword }] =
    usePutApiV1AccountChangePasswordMutation();
  const [resetPassword, { isLoading: isLoadingResetPassword }] =
    usePostApiV1AccountResetPasswordMutation();
  const [forgotPassword, { isLoading: isLoadingForgotPassword }] =
    usePostApiV1AccountForgotPasswordMutation();
  const isLoading =
    isLoadingChangePassword ||
    isLoadingForgotPassword ||
    isLoadingResetPassword;

  const handleSubmit: FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);

    if (phase === 1) {
      try {
        const res = await forgotPassword({
          body: { email: formData.get('email') as string },
        }).unwrap();
        if ((res as any)?.succeded !== false) {
          handleShowMessage({ handleClose });
          return;
        }
        throw new Error('NOT_SUCCESS');
      } catch (e: any) {
        if (e?.data?.messages?.[0] === 'NO_USER')
          return handleShowMessage({
            title: t('messages.noUser.title'),
            content: t('messages.noUser.content'),
          });
        if (e?.data?.messages?.[0] === 'USER_NOT_CONFIRMED')
          return handleShowMessage({
            title: t('messages.userNotConfirmed.title'),
            content: t('messages.userNotConfirmed.content'),
          });
        if (e?.data?.messages?.[0])
          return handleShowMessage({
            content: e.data.messages[0],
          });
        handleShowMessage();
      }
    } else {
      try {
        const body = Object.fromEntries(formData.entries());
        const res = !(body.token || body.email)
          ? await changePassword({
              body: body as ChangePassword,
            }).unwrap()
          : await resetPassword({
              body: body as ResetPassword,
            }).unwrap();
        if ((res as any)?.succeded !== false) {
          handleShowMessage({ handleClose });
          return;
        }
        throw new Error('NOT_SUCCESS');
      } catch (e: any) {
        if (e?.data?.messages?.[0])
          return handleShowMessage({
            content: e.data.messages[0],
          });
        handleShowMessage();
      }
    }
  };

  return (
    <>
      <AppDialog
        open={!!modals.password}
        onClose={handleClose}
        handleSubmit={handleSubmit}
        style={{ textAlign: 'center' }}
        maxWidth="xs"
        heading={<PasswordTitle phase={phase} />}
        content={<PasswordForm {...{ phase, isLoading }} />}
      />
      <AppDialog
        open={!!message.show}
        onClose={handleHideMessage}
        maxWidth="xs"
        heading={''}
        content={
          message.content || phase === 1 ? at('checkEmail') : at('success')
        }
      />
    </>
  );
}
