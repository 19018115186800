import { CSSProperties, useEffect, useRef, useState } from 'react';
import {
  BannerImage,
  BannerShell,
  BannerText,
  OverlayLink,
} from '../BannerElements';

import styles from '../BannerTemplate.module.css';

interface TemplateInitialProps {
  img?: string | null;
  title?: string | null;
  text?: string | null;
  linkText?: string | null;
  link?: string | null;
  animation?: string | null;
  style?: CSSProperties;
}

export function TemplateInitial({
  img,
  title,
  text,
  linkText,
  link,
  animation,
  style,
}: TemplateInitialProps) {
  const hasText = !!(title || text || linkText);
  const hasImg = !!img;
  const bannerShellClass = !hasText || !hasImg ? 'is-center' : '';

  const bannerRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const animateBanner = (entries: IntersectionObserverEntry[]) => {
      const [entry] = entries;
      if (!isVisible) setIsVisible(entry.isIntersecting);
    };
    const observer = new IntersectionObserver(animateBanner, {
      threshold: 1,
    });
    const bRef = bannerRef.current;
    if (bRef) observer.observe(bRef);
    return () => {
      if (bRef) observer.unobserve(bRef);
    };
  }, [bannerRef, isVisible]);

  return (
    <BannerShell style={style} className={bannerShellClass} ref={bannerRef}>
      {hasText && (
        <BannerText className="text-holder">
          {title && (
            <h3>
              <span>{title}</span>
            </h3>
          )}
          {text && <p>{text}</p>}
          {link && linkText && (
            <a href={link} target="_blank" rel="noreferrer">
              {linkText}
            </a>
          )}
        </BannerText>
      )}
      {hasImg && (
        <BannerImage
          className={[
            isVisible && animation && styles[animation as string],
            !isVisible && animation && 'hasAnimation',
            'image-holder',
          ]
            .filter(Boolean)
            .join(' ')}
        >
          <img src={img} alt={title || ''} role="presentation" />
        </BannerImage>
      )}
      {!!link && !linkText && (
        <OverlayLink href={link} target="_blank" rel="noreferrer">
          {linkText}
        </OverlayLink>
      )}
    </BannerShell>
  );
}
