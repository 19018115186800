import { createInstance, MatomoProvider } from '@jonkoops/matomo-tracker-react';
import { FC, PropsWithChildren } from 'react';

const matomoUrlBase = 'https://stat.zukunftsnetzwerk.digital';

const getMatomoSiteId = (): number => {
  const domain = window.location.hostname;
  if (domain.includes('zn-d.de')) return 2;
  if (domain.includes('pxnstage.one')) return 1;
  return 3;
};

const matomoInstance = createInstance({
  urlBase: matomoUrlBase,
  siteId: getMatomoSiteId(),
  linkTracking: false, // Important!
});

export const matomoDisabled = () => {
  return false;
  // return Cookies.get('accept-statistical') !== 't';
};

export const MatomoAnalyticsProvider: FC<PropsWithChildren<{}>> = ({
  children,
}) => <MatomoProvider value={matomoInstance}>{children}</MatomoProvider>;
