import { useModalsJoinNowSet } from 'hooks/useModalsJoinNowSet';
import { useAppDispatch } from 'hooks/useRedux';
import { useUser } from 'hooks/useUser';
import { toggleShowLiveSearch } from 'modules/Search/searchSlice';
import { ButtonSearch } from './HeaderElements';

export const SearchButton = () => {
  const setModalsRegister = useModalsJoinNowSet();
  const [user] = useUser();
  const isAuth = !!user;
  const dispatch = useAppDispatch();

  const toggleSearchBar = () => {
    if (!isAuth) {
      setModalsRegister(true);
      return;
    }
    dispatch(toggleShowLiveSearch());
  };

  return <ButtonSearch onClick={toggleSearchBar} />;
};
