import { useAppDispatch } from 'hooks/useRedux';
import { useCallback } from 'react';
import { handleModalsError } from 'store/slices/modalsSlice';

export function useModalsErrorSet() {
  const dispatch = useAppDispatch();
  return useCallback(
    (state: boolean) => dispatch(handleModalsError(state)),
    [dispatch]
  );
}
