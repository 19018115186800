import { AppDialog } from 'elements/AppDialog';
import { useTranslation } from 'react-i18next';

export function LoginErrorMessage({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) {
  const { t } = useTranslation();

  return (
    <AppDialog
      open={open}
      onClose={onClose}
      maxWidth="xs"
      heading={t('login.error')}
    />
  );
}
