import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

export const ContactsShell = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: theme.spacing(4),
  width: '100%',
}));

export const ContactsDetails = styled('div')(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  width: '100%',
  justifyContent: 'space-between',
  gap: theme.spacing(4),
}));

export const MapMessageShell = styled(Box)<{ isLoggedIn?: boolean }>(
  ({ theme, isLoggedIn }) => ({
    gap: theme.spacing(4),
    width: '100%',
    display: 'grid',
    gridTemplateColumns: isLoggedIn ? '1fr 1fr' : '1fr',
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
  })
);
