import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

export const BannerShell = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: `calc(100% + ${theme.spacing(6)})`,
  backgroundColor: theme.palette.grey[200],
  aspectRatio: '5/2',
  position: 'relative',
  '&.is-center': {
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    '& > div': {
      padding: 0,
      justifyContent: 'center',
    },
    '&.is-center .text-holder': {
      flexBasis: '75%',
    },
  },
  [theme.breakpoints.up('sm')]: {
    width: '100%',
    '&.is-center .text-holder': {
      flexBasis: '60%',
    },
  },
  [theme.breakpoints.up('lg')]: {
    '&.is-center .text-holder': {
      flexBasis: '50%',
    },
  },
}));

export const BannerText = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2.5),
  flexBasis: '60%',
  flexGrow: 0,
  '& h3': {
    margin: `0 0 ${theme.spacing(1)} 0`,
    '& span': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      display: 'inline-block',
      padding: `${theme.spacing(0.5)} ${theme.spacing(1.5)}`,
      fontSize: '0.625rem',
    },
  },
  '& p': {
    fontWeight: 'bold',
    fontSize: '0.75rem',
    marginBottom: theme.spacing(1),
    marginTop: 0,
  },
  '& a': {
    display: 'inline-block',
    textDecoration: 'none',
    backgroundColor: theme.palette.common.white,
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
    color: theme.palette.text.primary,
    fontWeight: 'bold',
    fontSize: '0.625rem',
    borderRadius: theme.spacing(0.5),
  },

  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(3),
    '& h3': {
      margin: `0 0 ${theme.spacing(3)} 0`,
      '& span': {
        padding: `${theme.spacing(0.5)} ${theme.spacing(1.5)}`,
        fontSize: '1.25rem',
      },
    },
    '& p': {
      fontSize: '1.25rem',
      marginBottom: theme.spacing(3),
    },
    '& a': {
      padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
      fontSize: '1rem',
    },
  },

  [theme.breakpoints.up('lg')]: {
    flexBasis: '50%',
    padding: `${theme.spacing(6)} 0 ${theme.spacing(6)} ${theme.spacing(6)}`,
    '& h3': {
      margin: `0 0 ${theme.spacing(4)} 0`,
      '& span': {
        padding: `${theme.spacing(1)} ${theme.spacing(3)}`,
        fontSize: '1.5rem',
      },
    },
    '& p': {
      fontSize: '1.5rem',
      marginBottom: theme.spacing(4),
    },
    '& a': {
      padding: `${theme.spacing(2)} ${theme.spacing(4)}`,
      fontSize: '1.25rem',
    },
  },
}));

export const BannerImage = styled(Box)(({ theme }) => ({
  flexBasis: '40%',
  flexGrow: 0,
  display: 'flex',
  justifyContent: 'flex-end',
  '&.hasAnimation': {
    opacity: 0,
  },
  '& img': {
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%',
    width: 'auto',
    height: 'auto',
  },
}));

export const OverlayLink = styled('a')(({ theme }) => ({
  position: 'absolute',
  left: 0,
  top: 0,
  width: '100%',
  height: '100%',
  fontSize: '0px',
}));

export const BannerShellImageOnly = styled(Box)(({ theme }) => ({
  width: `calc(100% + ${theme.spacing(6)})`,
  '& div': {
    display: 'block',
    width: '100%',
    position: 'relative',
    paddingTop: '40%',
  },
  '& img': {
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%',
    left: '50%',
    top: '50%',
    position: 'absolute',
    transform: 'translate(-50%, -50%)',
  },
  [theme.breakpoints.up('sm')]: {
    width: '100%',
  },
}));
