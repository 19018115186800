import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import { AppDialog } from 'elements/AppDialog';
import { useModalsErrorSet } from 'hooks/useModalsErrorSet';
import { useUnions } from 'hooks/useUnions';
import { FormEventHandler, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import {
  ContactsMessageShell,
  ContactsMessageSuccess,
  ContactsTextField,
} from './ContactsMessageElements';
import { ContactsMessageTitle } from './ContactsMessageTitle';

interface ContactMessageProps {
  unionId: number;
  informal: boolean;
}

export function ContactsMessage({ unionId, informal }: ContactMessageProps) {
  const { t } = useTranslation(undefined, { keyPrefix: 'contacts.message' });

  const [successOpen, setSuccessOpen] = useState(false);
  const setModalsError = useModalsErrorSet();
  const handleSuccessClose = () => setSuccessOpen(false);
  const [unions] = useUnions();

  const handleSubmit: FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    const subject = formData.get('subject');
    const message = formData.get('message');
    if (!subject || typeof subject !== 'string') return setModalsError(true);
    if (!message || typeof message !== 'string') return setModalsError(true);

    setModalsError(false);

    try {
      // NOTE: Endpoint's path is missleading, this is not really an endpoint for creating a contact but for sending a message to the union.
      const res = await fetch('/api/v1/contact/createcontact', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ subject, message, unionId }),
      });
      const data = await res.json();
      if (data.succeeded) {
        setSuccessOpen(true);
        (e.target as HTMLFormElement).reset();
      }
    } catch (err) {
      setModalsError(true);
    }
  };

  return (
    <>
      <ContactsMessageShell onSubmit={handleSubmit}>
        <ContactsMessageTitle />
        <ContactsTextField name="subject" label={t('subject')} />
        <ContactsTextField
          name="message"
          multiline
          rows={6}
          label={t('message')}
        />
        <Button variant="contained" fullWidth type="submit">
          {t('submit')}
        </Button>
      </ContactsMessageShell>
      <AppDialog
        maxWidth="xs"
        open={successOpen}
        onClose={handleSuccessClose}
        heading={
          <ContactsMessageSuccess>
            <Trans
              i18nKey={`success.${informal ? 'informal' : 'formal'}`}
              t={t}
            >
              {{ accent: t('accent') }}
            </Trans>
          </ContactsMessageSuccess>
        }
        content={
          <Box textAlign="center">
            <Divider />
            <img
              alt="Logo"
              src={unions.find((u) => u.id === unionId)?.logo}
              height={40}
              style={{ marginTop: '1.5rem' }}
            />
          </Box>
        }
      />
    </>
  );
}
