import Close from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import styled from '@mui/material/styles/styled';
import { FormEventHandler, ReactNode } from 'react';

const CloseButton = styled(Button)(({ theme }) => ({
  width: theme.spacing(4),
  height: theme.spacing(4),
  minWidth: theme.spacing(4),
  minHeight: theme.spacing(4),
  float: 'right',
}));
CloseButton.defaultProps = {
  children: <Close />,
  variant: 'contained',
  color: 'secondary',
};

const AppDialogShell = styled(Dialog)<{
  scale?: number;
  vertical?: 'start' | 'end';
}>(({ theme, scale = 1, vertical }) => ({
  '& [role="dialog"]': {
    alignSelf: vertical ? 'end' : undefined,
    borderRadius: theme.spacing(2 * scale),
    padding: theme.spacing(2 * scale),
    '& > form': {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(1.5 * scale),
    },
  },
}));

const AppDialogHeading = styled(DialogTitle)<{ scale?: number }>(
  ({ theme, scale = 1 }) => ({
    marginBottom: 0,
    padding: 0,
  })
);

const AppDialogContent = styled(DialogContent)<{ scale?: number }>(
  ({ theme, scale = 1 }) => ({
    marginvertical: 0,
    padding: 0,
  })
);

const AppDialogActions = styled(DialogActions)<{ scale?: number }>(
  ({ theme, scale = 1 }) => ({
    display: 'flex',
    flexWrap: 'wrap',
    gap: theme.spacing(1.5 * scale),
    padding: 0,
    '& > *': {
      margin: '0!important',
    },
  })
);

export interface AppDialogProps extends DialogProps {
  onClose?: () => void;
  hideCloseButton?: boolean;
  heading?: ReactNode;
  content?: ReactNode;
  actions?: ReactNode;
  handleSubmit?: FormEventHandler;
  scale?: number;
  vertical?: 'start' | 'end';
}

/**
 * AppDialog with unified styling.
 * NOTE: Use with: 100% when vertical arangement of actions elements is needed.
 */
export function AppDialog({
  open,
  onClose,
  hideCloseButton,
  heading,
  content,
  actions,
  handleSubmit,
  scale,
  vertical,
  ...rest
}: AppDialogProps) {
  return (
    <AppDialogShell {...{ open, onClose, scale, vertical, ...rest }}>
      {!hideCloseButton && (
        <div>
          <CloseButton onClick={onClose} />
        </div>
      )}
      <form onSubmit={handleSubmit} noValidate>
        {heading && (
          <AppDialogHeading {...{ scale }}>{heading}</AppDialogHeading>
        )}
        {content && (
          <AppDialogContent {...{ scale }}>{content}</AppDialogContent>
        )}
        {actions && (
          <AppDialogActions {...{ scale }}>{actions}</AppDialogActions>
        )}
      </form>
    </AppDialogShell>
  );
}
