import i18next from 'i18next';
import I18NextHttpBackend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

export function i18n() {
  const searchParams = new URLSearchParams(window.location.search);
  if (searchParams.has('lng')) {
    const lng = searchParams.get('lng');
    if (lng?.length) {
      localStorage.setItem('lng', lng);
    } else {
      localStorage.removeItem('lng');
    }
  }
  i18next
    .use(I18NextHttpBackend)
    .use(initReactI18next)
    .init({
      ns: 'web-app',
      backend: {
        loadPath: '/locales/{{lng}}.json',
        queryStringParams: { v: '0.0.6' }, // NOTE: change to invalidates browser cache
      },
      lng: localStorage.getItem('lng') || 'de',
      fallbackLng: 'de',
      interpolation: {
        escapeValue: false,
      },
    });
}
