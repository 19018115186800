import { FC, MouseEventHandler, useState } from 'react';
import { useGetApiV3DisruptorQuery } from 'store/api';
import { AlertContent, AlertShell, CloseButton } from './AlertElements';

export interface AlertProps {}

export const Alert: FC<AlertProps> = () => {
  const [showAlert, setShowAlert] = useState(true);
  const { data } = useGetApiV3DisruptorQuery(undefined);

  const handleClose: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation();
    setShowAlert(false);
  };

  const handleClick = data?.url
    ? () => {
        window.open(data.url, '_blank', 'noopener');
      }
    : undefined;

  return data && showAlert ? (
    <AlertShell onClick={handleClick}>
      <AlertContent>{data?.text}</AlertContent>
      <CloseButton onClick={handleClose} />
    </AlertShell>
  ) : null;
};
