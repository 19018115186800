import { isAuth } from 'helpers/isAuth';
import merge from 'lodash/merge';
import type { RootState } from 'store';
import { api } from './api';

export type Bands = {
  news?: number[];
  topics?: number[];
  committees?: number[];
  contacts?: number[];
  downloads?: number[];
};

export type Preferences = {
  bands: Bands;
};

const initPreferences: Preferences = { bands: {} };

export const apiPreferences = api.injectEndpoints({
  endpoints: (build) => ({
    patchApiV2AccountPreferences: build.mutation<
      unknown,
      { body: Preferences }
    >({
      queryFn: async ({ body }, { getState }, _, baseQuery) => {
        const state = getState() as RootState;
        const data = merge(
          {},
          state.api.queries['getApiV2AccountPreferences(undefined)']
            ?.data as Preferences,
          body
        );
        return await baseQuery({
          url: `/api/v2/account/preferences`,
          method: 'PATCH',
          data,
        });
      },
    }),

    getApiV2AccountPreferences: build.query<Preferences, undefined>({
      queryFn: async (_, __, ___, baseQuery) => {
        try {
          if (!isAuth()) throw new Error('notAuth');
          const result = (await baseQuery({
            url: `/api/v2/account/preferences`,
            method: 'GET',
          })) as any;
          if (!result?.data?.succeeded) throw new Error('notSucceeded');
          if (!result?.data?.data) throw new Error('noData');
          return { data: result.data.data as Preferences };
        } catch {
          return { data: initPreferences };
        }
      },
      providesTags: ['Auth'],
    }),
  }),
});

export const {
  endpoints: {
    getApiV2AccountPreferences: {
      useQueryState: useGetApiV2AccountPreferencesQueryState,
    },
  },
  useGetApiV2AccountPreferencesQuery,
  usePatchApiV2AccountPreferencesMutation,
} = apiPreferences;
