import TextField, { TextFieldProps } from '@mui/material/TextField';
import { forwardRef, ForwardRefExoticComponent } from 'react';

export const AppTextField: ForwardRefExoticComponent<TextFieldProps> =
  forwardRef((props, ref) => (
    <TextField
      ref={ref}
      variant="filled"
      InputProps={{
        disableUnderline: true,
        style: { borderRadius: 8 },
      }}
      autoComplete="username"
      {...props}
    />
  ));
