import type { Theme } from '@mui/material';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import styled from '@mui/material/styles/styled';
import SvgIcon from '@mui/material/SvgIcon';
import Tooltip from '@mui/material/Tooltip';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useModalsLoginSet } from 'hooks/useModalsLoginSet';
import { useTranslation } from 'react-i18next';
import { ReactComponent as AccountPlain } from './icons/account-plain.svg';

const ButtonParticipate = styled(Button)(({ theme }) => ({
  height: theme.spacing(3.5),
  minHeight: theme.spacing(3.5),
}));
ButtonParticipate.defaultProps = {
  variant: 'contained',
};

const ButtonIconParticipate = styled(IconButton)(({ theme }) => ({
  lineHeight: 0,
  padding: 0,
}));
ButtonIconParticipate.defaultProps = {
  children: (
    <SvgIcon>
      <AccountPlain />
    </SvgIcon>
  ),
  color: 'primary',
};

export const Participate = () => {
  const { t } = useTranslation();
  const setModalsLogin = useModalsLoginSet();
  const onClick = () => setModalsLogin(true);

  const mobile = !useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));
  return mobile ? (
    <Tooltip title={t('header.participateLabel')}>
      <ButtonIconParticipate onClick={onClick} />
    </Tooltip>
  ) : (
    <ButtonParticipate onClick={onClick}>
      {t('header.participate')}
    </ButtonParticipate>
  );
};
