import createTheme from '@mui/material/styles/createTheme';
import shadows, { Shadows } from '@mui/material/styles/shadows';

export const getTheme = ({
  buttonColor = '#C41570',
  fontAndHeadingColor = '#FFA500',
  backgroundFooterColor = '#FFF',
  fontFooterColor = '#C41570',
} = {}) =>
  createTheme({
    palette: {
      primary: { main: buttonColor, contrastText: '#FFF' },
      secondary: { main: '#9CA1B3', contrastText: '#FFF' },
      error: { main: '#F15D55', contrastText: '#FFF' },
      success: { main: '#1BBC64', contrastText: '#FFF' },
      accent: fontAndHeadingColor,
      footer: { main: backgroundFooterColor, contrastText: fontFooterColor },
      profile: { background: { paper: '#FFF', default: '#F9F9F9' } },
      text: { primary: '#414656', secondary: '#1A1B23' },
    },
    typography: {
      fontFamily: 'DMSans',
      button: {
        fontFamily: 'Poppins',
        textTransform: 'none',
        fontSize: 16,
        fontWeight: 700,
      },
    },
    shadows: shadows.map(() => 'none') as Shadows,
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: 8,
            height: 48,
            minHeight: 48,
          },
        },
      },
      MuiAppBar: {
        styleOverrides: {
          root: {
            backgroundColor: '#FFF',
            display: 'flex',
            alignItems: 'center',
            boxShadow: '0px 2px 8px 0px rgb(0 0 0 / 0.3)',
            width: '100%',
            position: 'relative',
          },
        },
      },
      MuiDivider: {
        styleOverrides: {
          root: { color: '#EAEAEA' },
        },
      },
      MuiDialog: {
        styleOverrides: {
          root: { backdropFilter: 'blur(9px)' },
        },
      },
      MuiAccordion: {
        styleOverrides: {
          root: {
            '&:before': {
              backgroundColor: 'white',
            },
          },
        },
      },
      MuiAccordionSummary: {
        styleOverrides: {
          root: { padding: 0 },
        },
      },
      MuiAccordionDetails: {
        styleOverrides: {
          root: { padding: 0 },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          input: { borderRadius: 'inherit' },
        },
      },
      MuiSwitch: {
        styleOverrides: {
          thumb: ({ ownerState: { checked } }) => ({
            background: buttonColor,
            boxShadow: '1px 1px 2px black',
            filter: checked ? undefined : 'brightness(5.4)',
          }),
        },
      },
    },
  });
