import { Banner as B } from 'store/api/apiBanner';
import { BannerTemplate } from './BannerTemplate';
interface BannerProps {
  banner: B;
}

export const Banner = ({ banner }: BannerProps) => {
  if (!banner) return null;
  return (
    <BannerTemplate
      templateId={banner.templateId}
      img={banner.imgSrc}
      title={banner.highlightedText}
      text={banner.text}
      linkText={banner.cta}
      link={banner.link}
      animation={banner.animation}
    />
  );
};
