import type { FC, ReactNode } from 'react';
import {
  ContactDetails,
  ContactElement,
  ContactHeader,
  ContactPortrait,
  ContactShell,
} from './ContactElements';

interface ContactProps {
  photo?: string;
  header?: string;
  name?: string;
  content?: ReactNode;
  phone?: string;
  fax?: string;
  email?: string;
  hidePhoto?: boolean;
  fullWidth?: boolean;
}

export const Contact: FC<ContactProps> = ({
  photo,
  header,
  name,
  content,
  phone,
  fax,
  email,
  hidePhoto,
  fullWidth,
}) => (
  <ContactShell fullWidth={fullWidth}>
    {!hidePhoto && <ContactPortrait photo={photo} />}
    <ContactDetails>
      {header && <ContactHeader mb={1}>{header}</ContactHeader>}
      {name && <ContactHeader>{name}</ContactHeader>}
      {content && <ContactDetails>{content}</ContactDetails>}
      <ContactElement type="phone" value={phone} />
      <ContactElement type="fax" value={fax} />
      <ContactElement type="email" value={email} />
    </ContactDetails>
  </ContactShell>
);
