import ZoomInRoundedIcon from '@mui/icons-material/ZoomInRounded';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import { styled } from '@mui/material/styles';
import SvgIcon from '@mui/material/SvgIcon';
import { useState } from 'react';

export const PhotoZoomTrigger = styled(Button)(({ theme }) => ({
  position: 'absolute',
  right: theme.spacing(1.25),
  top: theme.spacing(1.25),
  minWidth: 'unset',
  minHeight: 'unset',
  width: theme.spacing(4),
  height: theme.spacing(4),
  borderRadius: '50%',
}));
PhotoZoomTrigger.defaultProps = {
  children: (
    <SvgIcon>
      <ZoomInRoundedIcon />
    </SvgIcon>
  ),
  variant: 'contained',
};

interface PhotoZoomDialogProps {
  open: boolean;
  src?: string;
  alt?: string;
  onClose: () => void;
}

interface PhotoZoomProps {
  src?: string;
  alt?: string;
}

function PhotoZoomDialog({ onClose, src, alt, open }: PhotoZoomDialogProps) {
  return (
    <Dialog onClose={onClose} open={open}>
      <img src={src} alt={alt} />
    </Dialog>
  );
}

export function PhotoZoom({ src, alt }: PhotoZoomProps) {
  const [open, setOpen] = useState(false);
  if (!src) return null;

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      {!!src && <PhotoZoomTrigger onClick={handleOpen} />}
      <PhotoZoomDialog open={open} src={src} alt={alt} onClose={handleClose} />
    </>
  );
}
