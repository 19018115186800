import type { PaletteOptions } from '@mui/material';
import createTheme from '@mui/material/styles/createTheme';
import shadows, { Shadows } from '@mui/material/styles/shadows';

export const ooupsTheme = createTheme({
  palette: {
    primary: { main: '#C41570', contrastText: '#FFF' },
  } as PaletteOptions,
  typography: {
    fontFamily: 'DMSans',
    button: {
      fontFamily: 'Poppins',
      textTransform: 'none',
      fontSize: 16,
      fontWeight: 700,
    },
    h1: {
      fontFamily: 'Poppins',
      fontWeight: 'bold',
      fontSize: 26,
    },
  },
  shadows: shadows.map(() => 'none') as Shadows,
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          height: 48,
          minHeight: 48,
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: { color: '#EAEAEA', width: '100%' },
      },
    },
  },
});
