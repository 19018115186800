import Button from '@mui/material/Button';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Box, Container } from '@mui/system';
import { useTranslation } from 'react-i18next';

export function JoinNowActions({
  handleShowLoginForm,
  handleRegister,
}: {
  handleShowLoginForm: () => void;
  handleRegister: () => void;
}) {
  const { t } = useTranslation();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));

  if (matches) {
    return (
      <Container maxWidth={false} disableGutters>
        <Box display="flex" gap={theme.spacing(3)} flexDirection="column">
          <Button
            variant="contained"
            color="secondary"
            style={{ flex: 1 }}
            onClick={handleShowLoginForm}
            fullWidth
          >
            {t('joinNow.loginAction')}
          </Button>
          <Button
            variant="contained"
            color="primary"
            style={{ flex: 1 }}
            onClick={handleRegister}
            fullWidth
          >
            {t('joinNow.registerAction')}
          </Button>
        </Box>
      </Container>
    );
  }

  return (
    <>
      <Button
        variant="contained"
        color="secondary"
        style={{ flex: 1 }}
        onClick={handleShowLoginForm}
        fullWidth
      >
        {t('joinNow.loginAction')}
      </Button>
      <Button
        variant="contained"
        color="primary"
        style={{ flex: 1 }}
        onClick={handleRegister}
        fullWidth
      >
        {t('joinNow.registerAction')}
      </Button>
    </>
  );
}
