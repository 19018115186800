import type { PayloadAction } from '@reduxjs/toolkit';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'store';
import { TopicItem } from 'store/api';

const DEFAULT_LIMIT = 10;
const PAGE_BASE = 1;

type TopicItems = { [hIds: string]: TopicItem[][] };

interface TopicsState {
  items: TopicItems;
  page: { [hIds: keyof TopicItems]: number };
  total: { [hIds: keyof TopicItems]: number };
}

export type RequestByUnionId = {
  hUnionIds: string;
  page: number;
  limit?: number;
};

const h = (ids: number[]) => ids.join(',');

export const fetchTopicsPage = createAsyncThunk(
  'topics/fetch',
  async (
    { hUnionIds, page = PAGE_BASE, limit = DEFAULT_LIMIT }: RequestByUnionId,
    { getState }
  ) => {
    const state = getState() as RootState;
    const unionIds = hUnionIds.split(',');
    if (!unionIds.length) return;
    if (state.topics.items[hUnionIds]?.[page] !== undefined) return;

    const searchParams = new URLSearchParams([
      ['PageIndex', page.toString()],
      ['PageSize', limit.toString()],
      ...unionIds.map((s) => ['unionId', s]),
    ]);

    try {
      const response = await fetch('/api/v3/topics?' + searchParams);
      const data = await response.json();
      return data;
    } catch (err) {
      // TODO: handle errors
    }
  }
);

const initialState: TopicsState = {
  items: {},
  page: {},
  total: {},
};

const topicsSlice = createSlice({
  name: 'topics',
  initialState,
  reducers: {
    nextPage(state, action: PayloadAction<{ unionIds: number[] }>) {
      state.page[h(action.payload.unionIds)]++;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchTopicsPage.fulfilled, (state, action) => {
      if (action.payload === undefined) return;
      const items = action.payload.data?.items;
      if (!items) return;
      const { hUnionIds, page } = action.meta.arg;
      const total = action.payload.data?.totalPages;
      if (!state.items[hUnionIds]) state.items[hUnionIds] = [];
      state.items[hUnionIds][page] = items;
      state.page[hUnionIds] = page;
      state.total[hUnionIds] = total!;
    });
  },
});

export const { nextPage } = topicsSlice.actions;

export default topicsSlice.reducer;
