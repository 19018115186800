import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';

export const Accent = styled('span')(({ theme }) => ({
  color: theme.palette.accent,
}));

export const ContactsMessageSuccess = styled('span')(({ theme }) => ({
  '& strong': {
    color: theme.palette.accent,
  },
}));

export const ContactsMessageShell = styled('form')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  width: '100%',
}));

export const ContactsTextField = styled(TextField)(({ theme }) => ({}));
ContactsTextField.defaultProps = {
  InputProps: { disableUnderline: true, style: { borderRadius: 8 } },
  variant: 'filled',
  fullWidth: true,
};
