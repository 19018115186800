import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { AppPasswordField } from 'elements/AppPasswordField';
import { AppTextField } from 'elements/AppTextField';
import {
  ModalsPasswordEnum,
  useModalsPasswordSet,
} from 'hooks/useModalsPasswordSet';
import { useTranslation } from 'react-i18next';

export function LoginForm({
  handleRegister,
  isLoading,
}: {
  handleRegister: () => void;
  isLoading: boolean;
}) {
  const { t } = useTranslation(undefined, { keyPrefix: 'login' });

  const setModalsPassword = useModalsPasswordSet();

  return (
    <Box display="flex" gap={2} flexDirection="column">
      <Typography>{t('subtitle')}</Typography>
      <AppTextField
        name="email"
        type="email"
        required
        fullWidth
        label={t('emailLabel')}
      />
      <AppPasswordField
        name="password"
        required
        fullWidth
        label={t('passwordLabel')}
      />
      <Button
        variant="contained"
        color="primary"
        type="submit"
        disabled={isLoading}
      >
        {t('login')}
      </Button>
      <Link
        style={{ cursor: 'pointer', fontSize: '14px' }}
        onClick={() => setModalsPassword(ModalsPasswordEnum.init)}
      >
        {t('passwordForgotten')}
      </Link>
      <Divider style={{ marginBlock: '2rem' }} />
      <Typography>{t('noAccount')}</Typography>
      <Button
        variant="contained"
        color="primary"
        style={{ flex: 1 }}
        onClick={handleRegister}
      >
        {t('registerFor')}
      </Button>
    </Box>
  );
}
