import { BackButton } from 'elements/BackButton';
import { errorBoundary } from 'elements/ErrorBoundary';
import { Main } from 'elements/Main';
import { Committees } from 'modules/Committees';

export function CommitteesPage() {
  return (
    <Main>
      <BackButton />
      {errorBoundary(<Committees listAll />)}
    </Main>
  );
}
