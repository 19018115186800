import { getGender } from 'data/genders';
import { useUser } from 'hooks/useUser';
import { useTranslation } from 'react-i18next';
import {
  selectUserGenderId,
  selectUserInformal,
  selectUserNameFirst,
  selectUserNameLast,
} from 'selectors';

export function useApproach() {
  const { t } = useTranslation();
  const [user] = useUser();
  const informal = selectUserInformal(user);
  const genderId = selectUserGenderId(user);
  const nameFirst = selectUserNameFirst(user);
  const nameLast = selectUserNameLast(user);
  const genderless = genderId === 3 || genderId === 4;

  if (informal) return nameFirst;
  if (genderless) return `${nameFirst} ${nameLast}`;
  return `${t(`approach.gender.${getGender(genderId)}`)} ${nameLast}`;
}
