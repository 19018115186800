import Button from '@mui/material/Button';
import styled from '@mui/material/styles/styled';
import TextField from '@mui/material/TextField';
import { AppDialog } from 'elements/AppDialog';
import { useApproach } from 'hooks/useApproach';
import { useModals } from 'hooks/useModals';
import { useModalsCommentSet } from 'hooks/useModalsCommentSet';
import { useModalsErrorSet } from 'hooks/useModalsErrorSet';
import { FormEventHandler, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { usePostApiV2CommentsMutation } from 'store/api/apiFeedback';

const Strong = styled('span')(({ theme }) => ({
  color: theme.palette.primary.main,
}));

export default function CommentDialog() {
  const { t } = useTranslation(undefined, { keyPrefix: 'comment' });

  const modals = useModals();
  const setModalsComment = useModalsCommentSet();
  const approach = useApproach();
  const [sendComment] = usePostApiV2CommentsMutation();
  const [successOpen, setSuccessOpen] = useState(false);
  const setModalsError = useModalsErrorSet();

  const handleSubmit: FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    const value = formData.get('comment');
    if (!value || typeof value !== 'string') return setModalsError(true);
    const res = await sendComment({
      id: modals.comment!,
      value,
    }).unwrap();
    if ((res as any)?.succeded !== false) {
      setModalsComment();
      setSuccessOpen(true);
    } else {
      setModalsError(true);
    }
  };

  const handleClose = () => {
    setSuccessOpen(false);
    setModalsComment();
  };

  return (
    <>
      <AppDialog
        open={!!modals.comment && !successOpen}
        onClose={handleClose}
        handleSubmit={handleSubmit}
        heading={
          <>
            <Strong>{approach}</Strong>
            {t('title')}
          </>
        }
        content={
          <TextField
            name="comment"
            multiline
            fullWidth
            rows={6}
            sx={{
              '& .MuiInputLabel-root:not(.Mui-focused)': {
                whiteSpace: 'normal',
              },
            }}
            InputProps={{ disableUnderline: true, style: { borderRadius: 8 } }}
            label={t('placeholder')}
            variant="filled"
          />
        }
        actions={
          <Button variant="contained" fullWidth type="submit">
            {t('submit')}
          </Button>
        }
      />
      <AppDialog
        open={successOpen}
        onClose={handleClose}
        heading={t('successHeading')}
        content={t('successContent')}
        actions={
          <Button variant="contained" fullWidth onClick={handleClose}>
            {t('ok')}
          </Button>
        }
      />
    </>
  );
}
