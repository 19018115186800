import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Main } from 'elements/Main';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { routes } from 'routes';

export function NotFoundPage() {
  const { t } = useTranslation(undefined, { keyPrefix: 'notFound' });

  const navigate = useNavigate();

  return (
    <Main size="md" style={{ marginTop: 80 }}>
      <Typography variant="h1">404</Typography>
      <Typography variant="h5">{t('title')}</Typography>
      <Typography variant="subtitle1">{t('content')}</Typography>
      <Button
        variant="contained"
        sx={{ minWidth: 150 }}
        onClick={() => navigate(routes.HOME)}
      >
        {t('home')}
      </Button>
    </Main>
  );
}
