import Close from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import styled from '@mui/material/styles/styled';
import { PropsWithChildren } from 'react';

export const CloseButton = styled(Button)(({ theme }) => ({
  width: theme.spacing(3),
  height: theme.spacing(3),
  minWidth: theme.spacing(3),
  minHeight: theme.spacing(3),
  padding: 6,
  backgroundColor: theme.palette.primary.contrastText,
  float: 'right',
  '&:hover': {
    backgroundColor: theme.palette.grey[200],
  },
  '& svg': {
    fill: theme.palette.primary.main,
  },
}));
CloseButton.defaultProps = {
  children: <Close />,
  variant: 'contained',
};

export const AlertShell = styled(
  ({ onClick, ...rest }: PropsWithChildren<{ onClick?: () => void }>) => (
    <div
      role={!!onClick ? 'button' : undefined}
      tabIndex={!!onClick ? 0 : undefined}
      {...rest}
      onClick={onClick}
      onKeyDown={(e) => {
        if (e.key === ' ' || e.key === 'Enter' || e.key === 'Spacebar') {
          onClick?.();
        }
      }}
    >
      <div>{rest.children}</div>
    </div>
  )
)(({ theme, onClick }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  paddingBlock: theme.spacing(1.5),
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  cursor: !!onClick ? 'pointer' : undefined,
  '& > div': {
    width: '100%',
    textAlign: 'center',
    maxWidth: `min(calc(100vw - ${theme.spacing(6)}), ${
      theme.breakpoints.values.lg
    }px)`,
    display: 'flex',
  },
}));

export const AlertContent = styled('div')(({ theme }) => ({
  flex: 1,
  marginLeft: theme.spacing(4),
  marginRight: theme.spacing(1),
}));
